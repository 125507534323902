<template>
  <v-container class="pa-0 justify-start align-start flex-shrink-1 flex-grow-0" fluid>
    <v-row align="start" class="d-block justify-start" no-gutters>
      <v-system-bar color="deep-purple darken-3"></v-system-bar>
      <v-app-bar class="gradiant-market-digital" dark>
        <v-app-bar-nav-icon @click.stop="$emit('update:drawer', true)"></v-app-bar-nav-icon>

        <v-toolbar-title class="text-uppercase font-weight-light" style="white-space: normal; letter-spacing: .2rem"
          >Personnalisation du diaporama</v-toolbar-title
        >
      </v-app-bar>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "OptionAppBar",
  props: {
    drawerr: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
