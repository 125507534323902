<template>
  <v-row class="flex-column py-3" no-gutters>
    <slot name="additonal-options"></slot>
    <v-col>
      <!-- (:title="${my_concession_cSlides_list.length}") -->
      <c-tree-view
        :title="`Publicité de la concession`"
        :validation="Boolean(company_data.concession.name)"
        :is_selected="ads_treeview[0].parent"
        @click="company_data.concession.name && toggleParentPubliciteTree(0)"
      >
        <v-row justify="space-around">
          <ModeSlide
            :frequency_cSlides.sync="my_concession_frequency_cSlides"
            :cSlides_list="my_concession_cSlides_list"
            :mode="modeAllConcessionSlides"
            :label="!modeAllConcessionSlides ? 'Tout sélectionner' : 'Selection manuelle'"
            v-on:modeChange="
              (val) => {
                modeAllConcessionSlides = val;
                onModeAllSlidesChange('concession', modeAllConcessionSlides);
              }
            "
            storedMode="modeAllConcessionSlides"
            storedFrequency="concession_frequency_cSlides"
            v-on:update:mode-options="
              (val) => {
                modeAllConcessionSlides = val;
                onModeAllSlidesChange('concession', modeAllConcessionSlides);
              }
            "
          />
        </v-row>

        <Catalogue
          v-if="company_data.concession.name"
          class="pt-9"
          company_type="concession"
          :company_name="company_data.concession.name"
          :custom_slide_list="company_data.concession.custom_slides"
          :selected_custom_slide_list="my_concession_cSlides_list"
          :removable="false"
          :selectable="!modeAllConcessionSlides"
          :highlight="modeAllConcessionSlides"
          v-on:selected="handleCatalogueSelectSlide"
          v-on:on-img-error="catalogueConcession"
        />
      </c-tree-view>
      <c-tree-view
        :title="`Publicités groupes`"
        :validation="Boolean(company_data.groupe && company_data.groupe.length > 0)"
        :is_selected="ads_treeview[1].parent"
        @click="company_data.groupe && company_data.groupe.length > 0 && toggleParentPubliciteTree(1)"
      >
        <ModeSlide
          :frequency_cSlides.sync="my_groupe_frequency_cSlides"
          :cSlides_list="my_groupe_cSlides_list"
          :mode="modeAllGroupeSlides"
          :label="!modeAllGroupeSlides ? 'Tout sélectionner' : 'Selection manuelle'"
          v-on:modeChange="
            (val) => {
              modeAllGroupeSlides = val;
              onModeAllSlidesChange('groupe', modeAllGroupeSlides);
            }
          "
          storedMode="modeAllGroupeSlides"
          storedFrequency="groupe_frequency_cSlides"
          v-on:update:mode-options="
            (val) => {
              modeAllGroupeSlides = val;
              onModeAllSlidesChange('groupe', modeAllGroupeSlides);
            }
          "
        />
        <v-col v-for="(groupe, idx) in company_data.groupe" :key="groupe.ref_groupe_by_id.name">
          <c-tree-view
            :title="groupe.ref_groupe_by_id.name"
            :validation="Boolean(groupe.ref_groupe_by_id.name)"
            :is_selected="ads_treeview[1].children[idx]"
            @click="company_data.groupe && company_data.groupe.length > 0 && toggleChildrenPubliciteTree(1, idx)"
          >
            <Catalogue
              class="pt-9"
              company_type="groupe"
              :company_name="groupe.ref_groupe_by_id.name"
              :custom_slide_list="groupe.ref_groupe_by_id.custom_slide"
              :selected_custom_slide_list="my_groupe_cSlides_list"
              :removable="false"
              :selectable="!modeAllGroupeSlides"
              :highlight="modeAllGroupeSlides"
              v-on:selected="handleCatalogueSelectSlide"
              v-on:on-img-error="catalogueGroupe"
            />
          </c-tree-view>
        </v-col>
      </c-tree-view>

      <c-tree-view
        title="Publicités franchise"
        :validation="Boolean(company_data.franchise && company_data.franchise.length > 0)"
        :is_selected="ads_treeview[2].parent"
        @click="company_data.franchise && company_data.franchise.length > 0 && toggleParentPubliciteTree(2)"
      >
        <ModeSlide
          :frequency_cSlides.sync="my_franchise_frequency_cSlides"
          :cSlides_list="my_franchise_cSlides_list"
          :mode="modeAllFranchiseSlides"
          :label="!modeAllFranchiseSlides ? 'Tout sélectionner' : 'Selection manuelle'"
          v-on:modeChange="
            (val) => {
              modeAllFranchiseSlides = val;
              onModeAllSlidesChange('franchise', modeAllFranchiseSlides);
            }
          "
          storedMode="modeAllFranchiseSlides"
          storedFrequency="franchise_frequency_cSlides"
          v-on:update:mode-options="
            (val) => {
              modeAllFranchiseSlides = val;
              onModeAllSlidesChange('franchise', modeAllFranchiseSlides);
            }
          "
        />
        <v-col v-for="(franchise, idx) in company_data.franchise" :key="franchise.ref_franchise_by_id.name">
          <c-tree-view
            :title="franchise.ref_franchise_by_id.name"
            :validation="Boolean(franchise.ref_franchise_by_id.name)"
            :is_selected="ads_treeview[2].children[idx]"
            @click="company_data.franchise && company_data.franchise.length > 0 && toggleChildrenPubliciteTree(2, idx)"
          >
            <Catalogue
              class="pt-9"
              company_type="franchise"
              :company_name="franchise.ref_franchise_by_id.name"
              :custom_slide_list="franchise.ref_franchise_by_id.custom_slide"
              :selected_custom_slide_list="my_franchise_cSlides_list"
              :removable="false"
              :selectable="!modeAllFranchiseSlides"
              :highlight="modeAllFranchiseSlides"
              v-on:selected="handleCatalogueSelectSlide"
              v-on:on-img-error="catalogueFranchise"
            />
          </c-tree-view>
        </v-col>
      </c-tree-view>
    </v-col>
  </v-row>
</template>

<script>
import Catalogue from "@/components/Company/Catalogue";
import CTreeView from "@/components/TreeView/TreeView";
import ModeSlide from "@/components/Diapo/Options/Inputs/ModeSlide";

export default {
  name: "CPublicite",

  components: { CTreeView, ModeSlide, Catalogue },

  props: [
    "concession_cSlides_list",
    "groupe_cSlides_list",
    "franchise_cSlides_list",
    "concession_frequency_cSlides",
    "groupe_frequency_cSlides",
    "franchise_frequency_cSlides",
  ],

  data() {
    return {
      ads_treeview: [{ parent: false }, { parent: false, children: [] }, { parent: false, children: [] }], // concession, groupe, franchise

      company_data: {
        concession: {},
        groupe: [],
        franchise: [],
      },

      my_concession_cSlides_list: [],
      my_groupe_cSlides_list: [],
      my_franchise_cSlides_list: [],

      my_concession_frequency_cSlides: null,
      my_groupe_frequency_cSlides: null,
      my_franchise_frequency_cSlides: null,

      modeAllConcessionSlides: false,
      modeAllGroupeSlides: false,
      modeAllFranchiseSlides: false,
    };
  },

  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.catalogueConcession();
      this.catalogueGroupe();
      this.catalogueFranchise();
    },

    async catalogueConcession() {
      try {
        let company = (await this.$axios.get("/concession/catalogue"))?.data?.company;

        // this.company_data.concession.type = this.$route.params.type;
        this.company_data.concession.logo = company?.logo;
        this.company_data.concession.custom_slides = company?.custom_slide || [];
        this.company_data.concession.name = company?.name;

        // reinit my_concession_cSlides_list in case of img error and requesting again concession ...
        // this.my_concession_cSlides_list = [];
      } catch (error) {
        if (error?.response?.status != 401) {
          console.error(error);
          this.displayErrorSnack(error);
        }
      }
    },
    async catalogueGroupe() {
      try {
        this.company_data.groupe = (await this.$axios.get("/groupe/catalogue")).data.groupe ?? [];

        this.ads_treeview[1].children = [];
        this.company_data.groupe.map((el) => this.ads_treeview[1].children.push(false));

        // reinit my_groupe_cSlides_list in case of img error and requesting again groupes ...
        // this.my_groupe_cSlides_list = [];
      } catch (error) {
        if (error?.response?.status != 401) {
          console.error(error);
          this.displayErrorSnack(error);
        }
      }
    },

    async catalogueFranchise() {
      try {
        this.company_data.franchise = (await this.$axios.get("/franchise/catalogue")).data.franchise ?? [];

        this.ads_treeview[2].children = [];
        this.company_data.franchise.map((el) => this.ads_treeview[2].children.push(false));

        // reinit my_franchise_cSlides_list in case of img error and requesting again franchises ...
        // this.my_franchise_cSlides_list = [];
      } catch (error) {
        if (error?.response?.status != 401) {
          console.error(error);
          this.displayErrorSnack(error);
        }
      }
    },

    /**
     *
     * Add or remove slides into concession/groupe/franchise list on selection
     *
     * payload == { index, value, type, company_name }
     *
     * index: Integer
     * value: Boolean
     * type: String (either concession, groupe or franchise)
     * company_name: String
     */
    handleCatalogueSelectSlide(payload) {
      let mapping = [
        {
          type: "concession",
          process: this.selectConcessionCatalogue,
        },
        {
          type: "groupe",
          process: this.selectGroupeCatalogue,
        },
        {
          type: "franchise",
          process: this.selectFranchiseCatalogue,
        },
      ];
      let map_index = mapping.findIndex((el) => el.type == payload.type);

      if (map_index > -1) mapping[map_index].process(payload);
    },

    /**
     * Add or remove slides into/into concession list on selection
     *
     * payload == { index, value, type, company_name }
     *
     * index: Integer
     * value: Boolean
     * type: String (either concession, groupe or franchise)
     * company_name: String
     */
    selectConcessionCatalogue(payload) {
      console.log("selectConcessionCatalogue", payload, JSON.stringify(this.my_concession_cSlides_list));
      if (
        payload.index >= 0 &&
        payload.index < this.company_data.concession.custom_slides.length &&
        payload.value == true &&
        this.my_concession_cSlides_list.findIndex((el) => el === this.company_data.concession.custom_slides[payload.index]._id) ==
          -1
      ) {
        this.my_concession_cSlides_list.push(this.company_data.concession.custom_slides[payload.index]._id);
      } else if (
        payload.index >= 0 &&
        payload.index < this.company_data.concession.custom_slides.length &&
        payload.value == false
        // &&
        // this.my_concession_cSlides_list.findIndex((el) => el === this.company_data.concession.custom_slides[payload.index]._id) >
        //   -1
      ) {
        this.my_concession_cSlides_list.splice(payload.index, 1);
      }
    },

    /**
     * Add or remove slides into/into groupe list on selection
     *
     * payload == { index, value, type, company_name }
     *
     * index: Integer
     * value: Boolean
     * type: String (either concession, groupe or franchise)
     * company_name: String
     */
    selectGroupeCatalogue(payload) {
      let idx_groupe = -1;
      let idx_option = -1;

      if (
        (idx_groupe = this.company_data.groupe.findIndex((el) => el.ref_groupe_by_id.name == payload.company_name)) > -1 &&
        payload.index >= 0 &&
        payload.index < this.company_data.groupe[idx_groupe].ref_groupe_by_id.custom_slide.length &&
        payload.value == true &&
        (this.my_groupe_cSlides_list.filter((el) => el.name == payload.company_name).length == 0 ||
          ((idx_option = this.my_groupe_cSlides_list.findIndex((el) => el.name == payload.company_name)) > -1 &&
            this.my_groupe_cSlides_list[idx_option]?.slides.findIndex(
              (el) => el == this.company_data.groupe[idx_groupe].ref_groupe_by_id.custom_slide[payload.index]._id
            ) == -1))
      ) {
        // add into groupe list
        if (this.my_groupe_cSlides_list.filter((el) => el.name == payload.company_name).length == 0) {
          this.my_groupe_cSlides_list.push({
            name: payload.company_name,
            slides: [this.company_data.groupe[idx_groupe].ref_groupe_by_id.custom_slide[payload.index]._id],
          });
        } else if (
          idx_option > -1 &&
          this.my_groupe_cSlides_list[idx_option].slides.findIndex(
            (el) => el == this.company_data.groupe[idx_groupe].ref_groupe_by_id.custom_slide[payload.index]._id
          ) == -1
        ) {
          this.my_groupe_cSlides_list[idx_option].slides.push(
            this.company_data.groupe[idx_groupe].ref_groupe_by_id.custom_slide[payload.index]._id
          );
        }
      } else if (
        (idx_groupe = this.company_data.groupe.findIndex((el) => el.ref_groupe_by_id.name == payload.company_name)) > -1 &&
        payload.index >= 0 &&
        payload.index < this.company_data.groupe[idx_groupe].ref_groupe_by_id.custom_slide.length &&
        payload.value == false &&
        (idx_option = this.my_groupe_cSlides_list.findIndex((el) => el.name == payload.company_name)) > -1 &&
        this.my_groupe_cSlides_list[idx_option]?.slides.findIndex(
          (el) => el == this.company_data.groupe[idx_groupe].ref_groupe_by_id.custom_slide[payload.index]._id
        ) > -1
      ) {
        // remove from groupe list

        this.my_groupe_cSlides_list[idx_option].slides.splice(
          this.my_groupe_cSlides_list[idx_option].slides.findIndex(
            (el) => el == this.company_data.groupe[idx_groupe].ref_groupe_by_id.custom_slide[payload.index]._id
          ),
          1
        );

        if (this.my_groupe_cSlides_list[idx_option].slides.length == 0) this.my_groupe_cSlides_list.splice(idx_option, 1);
      }
    },

    /**
     * Add or remove slides into/into franchise list on selection
     *
     * payload == { index, value, type, company_name }
     *
     * index: Integer
     * value: Boolean
     * type: String (either concession, franchise or franchise)
     * company_name: String
     */
    selectFranchiseCatalogue(payload) {
      let idx_franchise = -1;
      let idx_option = -1;

      if (
        (idx_franchise = this.company_data.franchise.findIndex((el) => el.ref_franchise_by_id.name == payload.company_name)) >
          -1 &&
        payload.index >= 0 &&
        payload.index < this.company_data.franchise[idx_franchise].ref_franchise_by_id.custom_slide.length &&
        payload.value == true &&
        (this.my_franchise_cSlides_list.filter((el) => el.name == payload.company_name).length == 0 ||
          ((idx_option = this.my_franchise_cSlides_list.findIndex((el) => el.name == payload.company_name)) > -1 &&
            this.my_franchise_cSlides_list[idx_option]?.slides.findIndex(
              (el) => el == this.company_data.franchise[idx_franchise].ref_franchise_by_id.custom_slide[payload.index]._id
            ) == -1))
      ) {
        // add into franchise list
        if (this.my_franchise_cSlides_list.filter((el) => el.name == payload.company_name).length == 0) {
          this.my_franchise_cSlides_list.push({
            name: payload.company_name,
            slides: [this.company_data.franchise[idx_franchise].ref_franchise_by_id.custom_slide[payload.index]._id],
          });
        } else if (
          idx_option > -1 &&
          this.my_franchise_cSlides_list[idx_option].slides.findIndex(
            (el) => el == this.company_data.franchise[idx_franchise].ref_franchise_by_id.custom_slide[payload.index]._id
          ) == -1
        ) {
          this.my_franchise_cSlides_list[idx_option].slides.push(
            this.company_data.franchise[idx_franchise].ref_franchise_by_id.custom_slide[payload.index]._id
          );
        }
      } else if (
        (idx_franchise = this.company_data.franchise.findIndex((el) => el.ref_franchise_by_id.name == payload.company_name)) >
          -1 &&
        payload.index >= 0 &&
        payload.index < this.company_data.franchise[idx_franchise].ref_franchise_by_id.custom_slide.length &&
        payload.value == false &&
        (idx_option = this.my_franchise_cSlides_list.findIndex((el) => el.name == payload.company_name)) > -1 &&
        this.my_franchise_cSlides_list[idx_option]?.slides.findIndex(
          (el) => el == this.company_data.franchise[idx_franchise].ref_franchise_by_id.custom_slide[payload.index]._id
        ) > -1
      ) {
        // remove from franchise list
        this.my_franchise_cSlides_list[idx_option].slides.splice(
          this.my_franchise_cSlides_list[idx_option].slides.findIndex(
            (el) => el == this.company_data.franchise[idx_franchise].ref_franchise_by_id.custom_slide[payload.index]._id
          ),
          1
        );

        if (this.my_franchise_cSlides_list[idx_option].slides.length == 0) this.my_franchise_cSlides_list.splice(idx_option, 1);
      }
    },

    /**
     * index 0 == ads_treeview concession
     * index 1 == ads_treeview groupe
     * index 2 == ads_treeview franchise
     */
    toggleParentPubliciteTree(index) {
      let tmp = this.ads_treeview[index];
      tmp.parent = !tmp.parent;
      // if ads_treeview[index].parent becomes false then we reinit children to false too
      if (tmp.parent == false && tmp?.children) {
        tmp = this.resetChildrenPubliciteTree(tmp);
      }
      this.ads_treeview.splice(index, 1, tmp);
    },

    /**
     * index 0 == ads_treeview concession
     * index 1 == ads_treeview groupe
     * index 2 == ads_treeview franchise
     */
    toggleChildrenPubliciteTree(parent_idx, children_idx) {
      let tmp = this.ads_treeview[parent_idx];
      tmp.children[children_idx] = !tmp.children[children_idx];
      this.ads_treeview.splice(parent_idx, 1, tmp);
    },

    resetParentAndChildrenPubliciteTree() {
      if (this.panels.findIndex((el) => el == 1) > -1) {
        // if publicité panel has changed to false
        this.resetParentPubliciteTree(0);
        this.resetParentPubliciteTree(1);
        this.resetParentPubliciteTree(2);
      }
    },

    /**
     * index 0 == ads_treeview concession
     * index 1 == ads_treeview groupe
     * index 2 == ads_treeview franchise
     */
    resetParentPubliciteTree(index) {
      let tmp = this.ads_treeview[index];
      tmp.parent = false;
      tmp = this.resetChildrenPubliciteTree(tmp);
      this.ads_treeview.splice(index, 1, tmp);
    },

    /**
     * ads_tree_elem is either the ads_treeview concession / ads_treeview groupe / ads_treeview franchise
     */
    resetChildrenPubliciteTree(ads_tree_elem) {
      if (ads_tree_elem?.children?.length > 0) {
        ads_tree_elem.children.map((el, idx) => (ads_tree_elem.children[idx] = false));
      }
      return ads_tree_elem;
    },

    onModeAllSlidesChange(type, mode) {
      if (mode) this[`my_${type}_cSlides_list`] = "all";
      else {
        this[`my_${type}_cSlides_list`] = [];
        // this[`my_${type}_frequency_cSlides`] = null;
      }
    },
  },

  /** This weird stuff has been made so that it can be use as a standalone component OR in combinaison with a container component passing props */
  watch: {
    /**
     * Emit any changes to our custom variables
     */
    my_concession_cSlides_list: {
      handler: function(val) {
        if (val === "all") this.modeAllConcessionSlides = true;
        this.$emit("update:concession_cSlides_list", val);
      },
    },
    my_groupe_cSlides_list: {
      handler: function(val) {
        if (val === "all") this.modeAllGroupeSlides = true;
        this.$emit("update:groupe_cSlides_list", val);
      },
    },
    my_franchise_cSlides_list: {
      handler: function(val) {
        if (val === "all") this.modeAllFranchiseSlides = true;
        this.$emit("update:franchise_cSlides_list", val);
      },
    },
    my_concession_frequency_cSlides: {
      handler: function(val) {
        this.$emit("update:concession_frequency_cSlides", val);
      },
    },
    my_groupe_frequency_cSlides: {
      handler: function(val) {
        this.$emit("update:groupe_frequency_cSlides", val);
      },
    },
    my_franchise_frequency_cSlides: {
      handler: function(val) {
        this.$emit("update:franchise_frequency_cSlides", val);
      },
    },

    /**
     * update our custom variables with props variables
     */

    concession_cSlides_list: {
      handler: function(val) {
        this.my_concession_cSlides_list = val;
      },
      immediate: true,
    },
    groupe_cSlides_list: {
      handler: function(val) {
        this.my_groupe_cSlides_list = val;
      },
      immediate: true,
    },
    franchise_cSlides_list: {
      handler: function(val) {
        this.my_franchise_cSlides_list = val;
      },
      immediate: true,
    },
    concession_frequency_cSlides: {
      handler: function(val) {
        this.my_concession_frequency_cSlides = val;
      },
      immediate: true,
    },
    groupe_frequency_cSlides: {
      handler: function(val) {
        this.my_groupe_frequency_cSlides = val;
      },
      immediate: true,
    },
    franchise_frequency_cSlides: {
      handler: function(val) {
        this.my_franchise_frequency_cSlides = val;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
