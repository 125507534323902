<template>
  <v-row justify="space-around" class="mt-8">
    <!-- <v-switch :value="mode" :label="label" class="text-center" color="teal" @change="(val) => $emit('modeChange', val)"> -->
    <!-- </v-switch> -->
    <v-col :md="cSlides_list.length > 0 ? 6 : 12" class="text-center"
      ><v-btn :value="mode" color="orange" :outlined="mode" class="text-center" @click="(val) => $emit('modeChange', !mode)">{{
        label
      }}</v-btn>
    </v-col>
    <v-col md="6"
      ><CSlidesFrequency
        :frequency_cSlides="frequency_cSlides"
        :cSlides_list="cSlides_list"
        v-on:update:frequency_cSlides="(val) => $emit('update:frequency_cSlides', val)"
    /></v-col>
  </v-row>
</template>

<script>
import user from "@/utils/mixins/user";

import CSlidesFrequency from "@/components/Diapo/Options/Inputs/SlidesFrequency";

export default {
  name: "ModeSlide",

  components: { CSlidesFrequency },
  props: ["frequency_cSlides", "cSlides_list", "mode", "label", "storedMode", "storedFrequency"],

  mixins: [user],
};
</script>

<style lang="scss" scoped></style>
