<template>
  <v-row class="align-start justify-center fill-height" no-gutters>
    <v-col cols="12" lg="7">
      <v-skeleton-loader :loading="!request_extra_done" transition="scale-transition" height="94" type="list-item-two-line">
        <v-autocomplete
          :value="selected_cardiff_codes"
          :items="full_list_codes"
          :suffix="
            `${
              selected_cardiff_codes.length == 0
                ? 'codes console sont sélectionnés'
                : selected_cardiff_codes.length > 1
                ? 'servent de filtre'
                : 'sert de filtre'
            } `
          "
          return-object
          multiple
          chips
          deletable-chips
          clearable
          filled
          dense
          label="Code console de publication ( fichier véhicules )"
          placeholder="Tous les"
          persistent-hint
          hint="Par défaut : tous. Permet de sélectionner les fichiers véhicules que l'ont souhaite voir apparaître dans la vitrine."
          @change="
            (val) => {
              $emit('update:selected_cardiff_codes', val);
            }
          "
        ></v-autocomplete>
      </v-skeleton-loader>
    </v-col>
    <v-col cols="12" lg="7">
      <v-skeleton-loader :loading="!request_extra_done" transition="scale-transition" height="94" type="list-item-two-line">
        <v-autocomplete
          :value="selected_constructors"
          :items="sorted_list_constructor"
          :suffix="
            `${
              selected_constructors && selected_constructors.length == 0
                ? ' les marques sont sélectionnées'
                : selected_constructors && selected_constructors.length > 1
                ? 'servent de filtre'
                : !selected_constructors
                ? ''
                : 'sert de filtre'
            } `
          "
          item-text="constructor_name"
          multiple
          chips
          deletable-chips
          clearable
          filled
          dense
          label="Filtrer par marque"
          placeholder="Toutes"
          persistent-hint
          return-object
          hint="Par défaut : toutes. Permet de filtrer la sélection des véhicules que l'on souhaite voir apparaître dans la vitrine."
          @change="
            (val) => {
              $emit('update:selected_constructors', val);
            }
          "
        ></v-autocomplete>
      </v-skeleton-loader>
    </v-col>
    <v-col cols="12" lg="7">
      <v-skeleton-loader :loading="!request_extra_done" transition="scale-transition" height="94" type="list-item-two-line">
        <v-autocomplete
          :value="selected_qualifications"
          :items="full_list_qualification"
          item-text="label"
          :suffix="
            `${
              selected_qualifications && selected_qualifications.length == 0
                ? ' les qualifications sont sélectionnées'
                : selected_qualifications && selected_qualifications.length > 1
                ? 'servent de filtre'
                : !selected_qualifications
                ? ''
                : 'sert de filtre'
            } `
          "
          return-object
          multiple
          chips
          deletable-chips
          clearable
          filled
          dense
          label="Filtrer par qualification (VO / VD ...)"
          placeholder="Toutes"
          persistent-hint
          hint="Par défaut : toutes. Permet de sélectionner tout type de voiture."
          @change="
            (val) => {
              $emit('update:selected_qualifications', val);
            }
          "
        ></v-autocomplete>
      </v-skeleton-loader>
    </v-col>
    <v-col cols="12" lg="7">
      <v-skeleton-loader :loading="!request_extra_done" transition="scale-transition" height="94" type="list-item-two-line">
        <v-autocomplete
          :value="selected_genres"
          :items="sorted_list_genre"
          item-text="label"
          :suffix="
            `${
              selected_genres && selected_genres.length == 0
                ? ' les genres sont sélectionnées'
                : selected_genres && selected_genres.length > 1
                ? 'servent de filtre'
                : !selected_genres
                ? ''
                : 'sert de filtre'
            } `
          "
          return-object
          multiple
          chips
          deletable-chips
          clearable
          filled
          dense
          label="Filtrer par genre (voiture, utilitaire, moto ...)"
          placeholder="Toutes"
          persistent-hint
          hint="Par défaut : toutes. Permet de sélectionner tout genre de voiture."
          @change="
            (val) => {
              $emit('update:selected_genres', val);
            }
          "
        ></v-autocomplete>
      </v-skeleton-loader>
    </v-col>
  </v-row>
</template>

<script>
import user from "@/utils/mixins/user";

export default {
  name: "CFiltre",

  props: {
    request_extra_done: Boolean,

    selected_cardiff_codes: { type: Array, default: () => [] },
    selected_constructors: { type: Array, default: () => [] },
    selected_qualifications: { type: Array, default: () => [] },
    selected_genres: { type: Array, default: () => [] },

    full_list_codes: { type: Array, default: () => [] },
    full_list_constructor: { type: Array, default: () => [] },
    full_list_qualification: { type: Array, default: () => [] },
    full_list_genre: { type: Array, default: () => [] },
  },

  mixins: [user],

  computed: {
    sorted_list_genre() {
      this.full_list_genre;

      return this.full_list_genre.sort(function(a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
    },
    sorted_list_constructor() {
      this.full_list_constructor;

      return this.full_list_constructor.sort(function(a, b) {
        if (a.constructor_name < b.constructor_name) {
          return -1;
        }
        if (a.constructor_name > b.constructor_name) {
          return 1;
        }
        return 0;
      });
    },
  },

  watch: {
    full_list_codes: {
      immediate: true,
      handler: function(new_val, old_val) {
        // If actual selected cardiff codes contains any none up to date value then we remove them
        if (new_val.length && !this.selected_cardiff_codes.every((code) => new_val.includes(code))) {
          const filtered = this.selected_cardiff_codes.filter((cardiff) => new_val.includes(cardiff));
          this.$emit(`update:selected_cardiff_codes`, filtered);
        }
      },
    },
    /**
     * Useless filtering ? those list are fixed so why check if anything is outdated ?... Moreover, backend check those parameter before filtering
     */
    // full_list_constructor: {
    //   immediate: true,
    //   handler: function(new_val, old_val) {
    //     // If actual selected constructor contains any none up to date value then we remove them
    //     if (
    //       new_val.length &&
    //       !this.selected_constructors.every((constructor) => JSON.stringify(new_val).includes(JSON.stringify(constructor)))
    //     ) {
    //       const filtered = this.selected_constructors.filter((constructor) =>
    //         JSON.stringify(new_val).includes(JSON.stringify(constructor))
    //       );
    //       this.$emit(`update:selected_constructors`, filtered);
    //     }
    //   },
    // },
    // full_list_qualification: {
    //   immediate: true,
    //   handler: function(new_val, old_val) {
    //     // If actual selected qualifications contains any none up to date value then we remove them
    //     console.log(new_val, JSON.stringify(this.selected_qualifications));
    //     if (
    //       new_val.length > 0 &&
    //       !this.selected_qualifications.every((qualif) => new_val.map((el) => el.label).includes(qualif.label))
    //     ) {
    //       const filtered = this.selected_qualifications.filter((qualif) => new_val.map((el) => el.label).includes(qualif.label));
    //       this.$emit(`update:selected_qualifications`, filtered);
    //     }
    //   },
    // },
  },
};
</script>

<style lang="scss" scoped></style>
