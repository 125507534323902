<template>
  <v-row class="align-start justify-center fill-height" no-gutters>
    <v-col class="mt-10 pt-10" cols="11" xl="5">
      <CWeather
        class="justify-center"
        :local="local"
        :regional="regional"
        :national="national"
        :duration="duration"
        :interval="interval"
        v-on:update:local="
          (val) => {
            $emit('update:local', val);
          }
        "
        v-on:update:regional="
          (val) => {
            $emit('update:regional', val);
          }
        "
        v-on:update:national="
          (val) => {
            $emit('update:national', val);
          }
        "
        v-on:update:duration="
          (val) => {
            $emit('update:duration', val);
          }
        "
        v-on:update:interval="
          (val) => {
            $emit('update:interval', val);
          }
        "
      >
        <template v-slot:additonal-options>
          <v-col cols="12" lg="7">
            <v-btn @click="toggleFullScreen" color="deep-purple darken-3" class="mt-10" block>
              {{ `${!isFullscreen ? "Activer le mode plein écran" : "Désactiver le mode plein écran"}` }}</v-btn
            ></v-col
          >
        </template>
      </CWeather>
    </v-col>
    <v-col cols="12" xl="7" class="mt-12 mb-12 pb-12">
      <v-row class="p-relative" id="scene_weather" no-gutters>
        <v-btn
          v-if="local || regional || national"
          @click="toggleFullScreen"
          class="btn-fullscreen"
          color="deep-purple darken-3"
          fab
        >
          <v-icon>{{ `${!isFullscreen ? "mdi-fullscreen" : "mdi-fullscreen-exit"}` }}</v-icon></v-btn
        >
        <transition name="fade" mode="out-in">
          <!-- v-on:weather-ready="handleReady" -->
          <!-- :duration="0" -->

          <SceneWeather
            v-if="local || regional || national"
            :show="ready_weather"
            :intervalOnMounted="false"
            :interval="0"
            :duration="Number(duration) * 1000"
            :ref_weather="ref_weather"
            v-on:weather-end="handleEnd"
          />
        </transition>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import CWeather from "@/components/Diapo/Options/Inputs/Weather";
import SceneWeather from "@/components/Scenes/SceneWeather";

import screenfull from "screenfull";

export default {
  props: ["local", "regional", "national", "duration", "interval"],

  components: {
    CWeather,
    SceneWeather,
  },

  data() {
    return {
      /**
       * weather
       */
      ready_weather: true,
      ref_weather: [true, true, true],
      /**
       * screen
       */
      isFullscreen: false,
    };
  },

  mounted() {
    if (screenfull.isEnabled) {
      screenfull.on("change", this.onFullScreenChange);
    }
    if (this.local) this.ref_weather.splice(2, 1, false);
    if (this.regional) this.ref_weather.splice(1, 1, false);
    if (this.national) this.ref_weather.splice(0, 1, false);
  },

  beforeDestroy() {
    if (screenfull.isEnabled) {
      screenfull.off("change", this.onFullScreenChange);
    }
  },

  methods: {
    isReady() {
      // if (this.ref_weather.find((el) => el == false)) this.ready_weather = true;
    },

    async handleEnd() {
      this.ready_weather = false;
      await this.$nextTick();
      await this.$nextTick();
      this.ready_weather = true;
    },

    onFullScreenChange() {
      this.isFullscreen = !this.isFullscreen;
    },

    toggleFullScreen() {
      const element = document.getElementById("scene_weather");

      if (screenfull.isEnabled) {
        screenfull.toggle(element);
      }
    },
  },
  watch: {
    local: {
      handler: function(newval) {
        if (newval) {
          this.ref_weather.splice(2, 1, false);
          //   this.isReady();
        } else {
          this.ref_weather.splice(2, 1, true);
        }
      },
    },
    regional: {
      handler: function(newval) {
        if (newval) {
          this.ref_weather.splice(1, 1, false);
          //   this.isReady();
        } else {
          this.ref_weather.splice(1, 1, true);
        }
      },
    },
    national: {
      handler: function(newval) {
        if (newval) {
          this.ref_weather.splice(0, 1, false);
          //   this.isReady();
        } else {
          this.ref_weather.splice(0, 1, true);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-fullscreen {
  z-index: 6;
  opacity: 0.5;
  top: 20px;
  left: 20px;
  position: absolute;
}
</style>
