var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","lg":"7"}},[_c('v-checkbox',{attrs:{"input-value":_vm.display_details_car,"label":"Afficher le détail des véhicules","color":"success"},on:{"change":(val) => {
          _vm.$emit('update:display_details_car', val);
          if (!val) {
            _vm.$emit('update:detailsCar_overImage', true);
            _vm.$emit('update:detailsCar_right', false);
            _vm.$emit('update:smallDetailsCar', false);
          }
        }}})],1),_c('v-expand-transition',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.display_details_car),expression:"display_details_car"}],staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"cols":"12","lg":"7"}},[_c('v-text-field',{attrs:{"filled":"","suffix":"secondes par photo","value":_vm.nbr_second_per_photo,"placeholder":"7","persistent-hint":"","label":"Nombre de seconde par photo","hint":"Par défaut : 7. Pour faire défiler une photo toute les 25 secondes taper 25."},on:{"change":(val) => {
                if (val) _vm.$emit('update:nbr_second_per_photo', val);
              }}})],1),_c('v-col',{attrs:{"cols":"12","lg":"7"}},[_c('v-text-field',{attrs:{"filled":"","suffix":"photo(s) des véhicules","value":_vm.max_photo_per_vehicule,"placeholder":"Toutes les","persistent-hint":"","label":"Nombre de photo par véhicule","hint":"Par défaut : toutes. Laisser ce champs vide pour faire défiler toutes les photos de chaque véhicule."},on:{"change":(val) => {
                if (val) _vm.$emit('update:max_photo_per_vehicule', val);
              }}})],1),_c('v-col',{attrs:{"cols":"12","lg":"7"}}),_c('v-col',{attrs:{"cols":"12","lg":"7"}},[_c('v-checkbox',{attrs:{"input-value":_vm.detailsCar_overImage === false,"persistent-hint":"","hint":"Mettre côte à côte les détail du véhicule et la photo","label":"Détails du véhicule à côté de la photo","color":"success"},on:{"change":(val) => {
                _vm.$emit('update:detailsCar_overImage', !val);
                if (_vm.smallDetailsCar) _vm.$emit('update:smallDetailsCar', false);
              }}})],1),_c('v-col',{attrs:{"cols":"12","lg":"7"}},[_c('v-checkbox',{attrs:{"input-value":_vm.detailsCar_right,"persistent-hint":"","hint":"Changer les détails véhicule de place","label":"Détails du véhicule à droite","color":"success"},on:{"change":(val) => {
                _vm.$emit('update:detailsCar_right', val);
                if (_vm.smallDetailsCar) _vm.$emit('update:smallDetailsCar', false);
              }}})],1),_c('v-col',{attrs:{"cols":"12","lg":"7"}},[_c('v-checkbox',{attrs:{"input-value":_vm.smallDetailsCar,"persistent-hint":"","hint":"Choisir la version minimaliste du détails véhicule","label":"Détails du voiture mode miniature","color":"success"},on:{"change":(val) => {
                _vm.$emit('update:smallDetailsCar', val);
                if (val) {
                  _vm.$emit('update:detailsCar_overImage', true);
                  _vm.$emit('update:detailsCar_right', false);
                }
              }}})],1),_vm._t("additonal-options")],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }