<template>
  <!-- v-navigation-drawer  bottom props-->
  <!--  style="max-height: 80vh; overflow-y: auto" -->
  <v-navigation-drawer :value="drawer" absolute temporary v-on:input="updatedInput">
    <v-list shaped nav dense>
      <v-subheader>Catégories</v-subheader>
      <v-list-item-group @change="onItemClicked">
        <v-list-item v-for="(item, i) in options" :key="'options_' + i">
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="my-4"></v-divider>

        <v-list-item v-for="(item, i) in actions" :key="'actions' + i" class="my-1" @click="item.click()">
          <v-btn class="my-auto" :color="item.color" block>
            <v-icon v-if="item.icon" left dark> {{ item.icon }} </v-icon>{{ item.text }}
          </v-btn>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import user from "@/utils/mixins/user";

export default {
  name: "Options_NavigationDrawer",

  mixins: [user],

  props: {
    drawer: {
      type: Boolean,
      default: true,
    },
    onStartDiapo: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      options: [],
      actions: [],
    };
  },

  mounted() {
    this.options = [
      { text: "Logo", label: "logo", icon: "mdi-image", click: "" },
      { text: "Photo", label: "photo", icon: "mdi-car-side", click: "" },
      { text: "Filtre", label: "filtre", icon: "mdi-filter", click: "" },
      { text: "Publicité", label: "publicite", icon: "mdi-movie", click: "" },
      // { text: "Erreur", label: "erreur", icon: "mdi-flag", click: "" },
    ];
    if (this.isRole("weather"))
      this.options.push({ text: "Météo", label: "weather", icon: "mdi-weather-partly-cloudy", click: "" });
    if (this.isRole("traffic")) this.options.push({ text: "Trafic", label: "trafic", icon: "mdi-flag", click: "" });

    this.actions = [{ text: "Démarrer", icon: "mdi-play", click: this.onStartDiapo, color: "success" }];
  },

  methods: {
    onItemClicked(index) {
      if (this.options.length > index) this.emitOptionChange(this.options[index].label);
    },

    emitOptionChange(value) {
      this.$emit("option-change", value);
    },

    updatedInput(value) {
      if (this.drawer != value) this.$emit("update:drawer", value);
    },
  },
};
</script>

<style lang="scss" scoped>
.limit-list {
  max-height: 80%;
  overflow-y: auto;
}
.limit-button {
  min-height: 20%;
  overflow-y: auto;
}
</style>
