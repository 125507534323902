<template>
  <SceneBase :noCursor="false">
    <v-container fluid class="pa-0 text--white align-start">
      <AppBar :drawer.sync="drawer" />
      <NavigationDrawer :drawer.sync="drawer" v-on:option-change="onOptionChange" :onStartDiapo="startDiapo" />
      <v-row class="align-start fill-height" v-if="init" no-gutters>
        <BLogo
          v-if="selectedItem == 'logo'"
          :type.sync="logo.type"
          :company_name.sync="logo.company_name"
          :loyer="loyer.loyer"
          :loyer_duration="loyer.duration"
          :loyer_taeg="loyer.taeg"
          :display_details_car="display_details_car"
          :detailsCar_overImage="photo.detailsCar_overImage"
          :detailsCar_right="photo.detailsCar_right"
          :smallDetailsCar="smallDetailsCar"
          :samples="car_samples"
        />

        <BPhotos
          v-if="selectedItem == 'photo'"
          :nbr_second_per_photo.sync="photo.nbr_second_per_photo"
          :max_photo_per_vehicule.sync="photo.max_photo_per_vehicule"
          :display_details_car.sync="display_details_car"
          :detailsCar_overImage.sync="photo.detailsCar_overImage"
          :detailsCar_right.sync="photo.detailsCar_right"
          :smallDetailsCar.sync="smallDetailsCar"
          :loyer="loyer.loyer"
          :loyer_duration="loyer.duration"
          :loyer_taeg="loyer.taeg"
          :samples="car_samples"
        />

        <BFilters
          v-if="selectedItem == 'filtre'"
          :selected_cardiff_codes.sync="filters.selected_cardiff_codes"
          :selected_constructors.sync="filters.selected_constructors"
          :selected_qualifications.sync="filters.selected_qualifications"
          :selected_genres.sync="filters.selected_genres"
        />

        <BWeather
          v-if="selectedItem == 'weather'"
          :local.sync="weather.local"
          :regional.sync="weather.regional"
          :national.sync="weather.national"
          :duration.sync="weather.duration"
          :interval.sync="weather.interval"
        />

        <BTrafic
          v-if="selectedItem == 'trafic'"
          :trafic.sync="trafic.trafic"
          :duration.sync="trafic.duration"
          :interval.sync="trafic.interval"
        />

        <BPublicite
          v-if="selectedItem == 'publicite'"
          :concession_cSlides_list.sync="publicite.concession_cSlides_list"
          :groupe_cSlides_list.sync="publicite.groupe_cSlides_list"
          :franchise_cSlides_list.sync="publicite.franchise_cSlides_list"
          :concession_frequency_cSlides.sync="publicite.concession_frequency_cSlides"
          :groupe_frequency_cSlides.sync="publicite.groupe_frequency_cSlides"
          :franchise_frequency_cSlides.sync="publicite.franchise_frequency_cSlides"
        />
      </v-row>
    </v-container>
  </SceneBase>
</template>

<script>
import Logo from "@/components/Diapo/Logo/Logo.vue";

import NavigationDrawer from "@/components/Diapo/Options/Sidebar/NavigationDrawer";
import AppBar from "@/components/Diapo/Options/Sidebar/AppBar";

import default_options_values from "@/utils/defaultOptionsValues";

import snackBar from "@/utils/mixins/snackBar";
import user from "@/utils/mixins/user";
/**
 * Scene
 */
import SceneBase from "@/components/Scenes/Base";

/**
 * Bodies
 */
import BPhotos from "@/components/Diapo/Options/Body/BPhotos";
import BFilters from "@/components/Diapo/Options/Body/BFilters";
import BWeather from "@/components/Diapo/Options/Body/BWeather";
import BTrafic from "@/components/Diapo/Options/Body/BTrafic";
import BPublicite from "@/components/Diapo/Options/Body/BPublicite";
import BLogo from "@/components/Diapo/Options/Body/BLogo";

export default {
  name: "SceneOptions",

  components: {
    Logo,
    AppBar,
    NavigationDrawer,
    SceneBase,
    BPhotos,
    BFilters,
    BWeather,
    BTrafic,
    BPublicite,
    BLogo,
  },

  mixins: [snackBar, user],

  data() {
    return {
      init: false,
      /**
       * Sidebar
       */

      drawer: false,

      /**
       * General
       */
      selectedItem: "logo",

      /**
       * Photo
       */
      photo: {
        max_photo_per_vehicule: default_options_values.max_photo_per_vehicule,
        nbr_second_per_photo: default_options_values.nbr_second_per_photo,
        detailsCar_overImage: default_options_values.detailsCar_overImage,
        detailsCar_right: default_options_values.detailsCar_right,
      },

      /**
       * DetailsCar - should be in photo object ?
       */
      display_details_car: true,
      smallDetailsCar: false,

      /**
       * Loyer
       */
      loyer: {
        loyer: false,
        duration: default_options_values.loyer_duration,
        taeg: default_options_values.loyer_taeg,
      },
      /**
       * Filters
       */
      filters: {
        selected_cardiff_codes: [],
        selected_constructors: [],
        selected_qualifications: [],
        selected_genres: []
      },

      /**
       * Weather
       */
      weather: {
        local: false,
        regional: false,
        national: false,
        duration: default_options_values.weather_duration,
        interval: default_options_values.weather_interval,
      },

      /**
       * Trafic
       */
      trafic: {
        trafic: false,
        duration: default_options_values.trafic_duration,
        interval: default_options_values.trafic_interval,
      },

      /**
       * Publicite
       */
      publicite: {
        concession_cSlides_list: [],
        groupe_cSlides_list: [],
        franchise_cSlides_list: [],

        concession_frequency_cSlides: default_options_values.concession_frequency_cSlides,
        groupe_frequency_cSlides: default_options_values.groupe_frequency_cSlides,
        franchise_frequency_cSlides: default_options_values.franchise_frequency_cSlides,
      },

      /**
       * Logo
       */
      logo: {
        type: default_options_values.logo_type, // concession // groupe // franchise
        company_name: null, // only for type == groupe || type == franchise
      },

      /**
       * specific car samples
       * used only in options in order to
       * display specific car to specific client
       */
      car_samples: undefined,
    };
  },

  mounted() {
    this.apply_store_user_options();
    this.getSamples();
  },

  methods: {
    onOptionChange(index) {
      console.log(index);
      this.selectedItem = index;
    },

    async deleteDiapoSession() {
      try {
        await this.$axios.delete("/diapo");
      } catch (error) {
        console.error(error);
        this.displayErrorSnack(error);
      }
    },

    update_store_user_options() {
      this.set_user({
        ...this.get_user,
        options_diapo: {
          display_details_car: this.display_details_car,
          smallDetailsCar: this.smallDetailsCar,

          photo: {
            ...this.photo,
          },
          loyer: {
            ...this.loyer,
          },
          filters: {
            ...this.filters,
          },
          weather: {
            ...this.weather,
          },
          trafic: {
            ...this.trafic,
          },
          publicite: {
            ...this.publicite,
          },
          logo: {
            ...this.logo,
          },
        },
      });
    },

    apply_store_user_options() {
      if (this.get_user?.options_diapo?.display_details_car)
        this.display_details_car = this.get_user.options_diapo.display_details_car;
      if (this.get_user?.options_diapo?.smallDetailsCar) this.smallDetailsCar = this.get_user.options_diapo.smallDetailsCar;

      //photo
      for (const key in this.get_user?.options_diapo?.photo) {
        if (this.photo.hasOwnProperty(key)) this.photo[key] = this.get_user.options_diapo.photo[key];
      }
      //loyer
      for (const key in this.get_user?.options_diapo?.loyer) {
        if (this.loyer.hasOwnProperty(key)) this.loyer[key] = this.get_user.options_diapo.loyer[key];
      }
      //filters
      for (const key in this.get_user?.options_diapo?.filters) {
        if (this.filters.hasOwnProperty(key)) this.filters[key] = this.get_user.options_diapo.filters[key];
      }
      //weather
      for (const key in this.get_user?.options_diapo?.weather) {
        if (this.weather.hasOwnProperty(key)) this.weather[key] = this.get_user.options_diapo.weather[key];
      }
      //trafic
      for (const key in this.get_user?.options_diapo?.trafic) {
        if (this.trafic.hasOwnProperty(key)) this.trafic[key] = this.get_user.options_diapo.trafic[key];
      }
      //publicite
      for (const key in this.get_user?.options_diapo?.publicite) {
        // console.log(key, this.get_user?.options_diapo?.publicite[key]);
        if (this.publicite.hasOwnProperty(key)) this.publicite[key] = this.get_user.options_diapo.publicite[key];
        // console.log(JSON.stringify(this.publicite[key]));
      }
      //logo
      for (const key in this.get_user?.options_diapo?.logo) {
        if (this.logo.hasOwnProperty(key)) this.logo[key] = this.get_user.options_diapo.logo[key];
        // console.log(this.logo[key]);
      }

      this.init = true;
    },

    async startDiapo() {
      this.update_store_user_options();

      await this.deleteDiapoSession();

      this.$router.push({
        name: "diapo",
      });
    },

    async getSamples() {
      try {
        this.car_samples = (await this.$axios.get("/mysample"))?.data?.car_list[0];
      } catch (error) {
        console.error(error);
        this.car_samples = {
          marque: "RENAULT",
          famille: "SCENIC",
          version: "1.5 dCi 110",
          price: "19900",
          reference: "3653",
          annee: "2019",
          energie: "Diesel",
          km: "29051",
          boite: "Manuelle",
          equipement:
            "ABS|Aideau freinage d'urgence|Airbags frontaux|Airbags latéraux avant|Alarme volumétrique|AntipatinageAntivol|Climautomatique|Détecteur de sous-gonflage|Direction assistée|ESP|Feux arrière à LED|Fixation Isofix siège passageravant|Fixations Isofix aux places arrières|GPS Cartographique|Jantes Alu 19\"|Kit mains-libres Bluetooth|Lunette ARDegivrante|Ordinateur de bord|Peinture métal|Phares antibrouillard|Phares halogènes|Phares xénon|Prise iPod|PriseUSB|Radar de recul|Radio CD MP3|Régulateur de vitesse|Répartiteur électronique de freinage|Rétroviseur intérieurélectrochrome|Rétroviseurs électriques dégivrants|Rétroviseurs extérieurs à mémoire|Rétroviseurs rabattablesélectriquement|Sellerie cuir & alcantara|Siège conduct réglable hauteur-lombaire|Siège passager réglablehauteur-lombaire|Sièges avant électriques à mémoire|Sièges sport|Verrouillage centralisé à distance|Vitres avantélectriques|Volant réglable en profondeur et hauteur",
          peinture: { ext: "ORANGE", int: "NOIR" },
          photoList: ["https://i.imgur.com/VUzTYbl.jpg"],
          code: "demo",
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
