<template>
  <v-row class="align-start justify-center fill-height" no-gutters>
    <v-col class="mt-10 pt-10" cols="11" xl="5">
      <CPhotos
        class="justify-center"
        :nbr_second_per_photo="nbr_second_per_photo"
        :max_photo_per_vehicule="max_photo_per_vehicule"
        :display_details_car="display_details_car"
        :detailsCar_overImage="detailsCar_overImage"
        :detailsCar_right="detailsCar_right"
        :smallDetailsCar="smallDetailsCar"
        v-on:update:nbr_second_per_photo="
          (val) => {
            $emit('update:nbr_second_per_photo', val);
          }
        "
        v-on:update:max_photo_per_vehicule="
          (val) => {
            $emit('update:max_photo_per_vehicule', val);
          }
        "
        v-on:update:display_details_car="
          (val) => {
            $emit('update:display_details_car', val);
          }
        "
        v-on:update:detailsCar_overImage="
          (val) => {
            $emit('update:detailsCar_overImage', val);
          }
        "
        v-on:update:detailsCar_right="
          (val) => {
            $emit('update:detailsCar_right', val);
          }
        "
        v-on:update:smallDetailsCar="
          (val) => {
            $emit('update:smallDetailsCar', val);
          }
        "
      >
        <template v-slot:additonal-options>
          <v-col cols="12" lg="7">
            <v-btn @click="toggleFullScreen" color="deep-purple darken-3" class="mt-10" block>
              {{ `${!isFullscreen ? "Activer le mode plein écran" : "Désactiver le mode plein écran"}` }}</v-btn
            ></v-col
          >
        </template>
      </CPhotos>
    </v-col>
    <v-col cols="12" xl="7" class="mt-12 mb-12 pb-12">
      <v-row class="p-relative" id="scene_vehicule" no-gutters>
        <v-btn @click="toggleFullScreen" class="btn-fullscreen" color="deep-purple darken-3" fab>
          <v-icon>{{ `${!isFullscreen ? "mdi-fullscreen" : "mdi-fullscreen-exit"}` }}</v-icon></v-btn
        >
        <transition name="fade" mode="out-in">
          <SceneVehicule
            v-if="displaySceneVehicule"
            :url_image="samples && samples.photoList && samples.photoList[0] ? samples.photoList[0] : null"
            :display_top_title="$vuetify.breakpoint.mdAndUp"
            :car_data="samples"
            :loyer="loyer"
            :loyer_duration="Number(loyer_duration)"
            :loyer_taeg="Number(loyer_taeg) / 100"
            :display_details_car="display_details_car"
            :detailsCar_overImage="detailsCar_overImage"
            :detailsCar_right="detailsCar_right"
            :smallDetailsCar="smallDetailsCar"
            :duration="Number(nbr_second_per_photo) * 1000"
            v-on:vehicule-end="onVehiculeEnd"
            v-on:on-image-error="onImageError"
          >
          </SceneVehicule>
        </transition>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import CPhotos from "@/components/Diapo/Options/Inputs/Photos";
import SceneVehicule from "@/components/Scenes/SceneVehicule";

import screenfull from "screenfull";

export default {
  name: "BPhotos",

  components: {
    CPhotos,
    SceneVehicule,
  },

  props: {
    samples: {
      type: Object,
      default: () => ({}),
    },
    nbr_second_per_photo: {
      type: null,
    },
    max_photo_per_vehicule: {
      type: null,
    },
    detailsCar_overImage: {
      type: null,
    },
    detailsCar_right: {
      type: null,
    },
    smallDetailsCar: {
      type: null,
    },
    loyer: {
      type: null,
    },
    loyer_duration: {
      type: null,
    },
    loyer_taeg: {
      type: null,
    },
    display_details_car: {
      type: null,
    },
  },

  data() {
    return {
      /**
       * Photo
       */
      displaySceneVehicule: true,
      /** End of Photo */
      isFullscreen: false,
    };
  },

  mounted() {
    if (screenfull.isEnabled) {
      screenfull.on("change", this.onFullScreenChange);
    }
  },

  beforeDestroy() {
    if (screenfull.isEnabled) {
      screenfull.off("change", this.onFullScreenChange);
    }
  },

  methods: {
    async onVehiculeEnd() {
      this.displaySceneVehicule = false;
      await this.$nextTick();
      await new Promise((resolved) => setTimeout(resolved, 1000));
      this.displaySceneVehicule = true;
    },

    async onImageError() {
      this.onVehiculeEnd();
    },

    onFullScreenChange() {
      this.isFullscreen = !this.isFullscreen;
    },

    toggleFullScreen() {
      const element = document.getElementById("scene_vehicule");

      if (screenfull.isEnabled) {
        screenfull.toggle(element);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-fullscreen {
  z-index: 6;
  opacity: 0.5;
  top: 20px;
  left: 20px;
  position: absolute;
}
</style>
