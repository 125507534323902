<template>
  <v-row class="align-start justify-center fill-height" no-gutters>
    <v-col class="mt-10 pt-10" cols="11" xl="5">
      <CLogo
        class="justify-center"
        :type="type"
        :company_name="company_name"
        v-on:update:type="
          (val) => {
            $emit('update:type', val);
          }
        "
        v-on:update:company_name="
          (val) => {
            $emit('update:company_name', val);
          }
        "
        v-on:logo_link="
          (val) => {
            logo_url = val;
          }
        "
      >
        <template v-slot:additonal-options>
          <v-col cols="12" lg="7">
            <v-btn @click="toggleFullScreen" color="deep-purple darken-3" class="mt-10" block>
              {{ `${!isFullscreen ? "Activer le mode plein écran" : "Désactiver le mode plein écran"}` }}</v-btn
            ></v-col
          >
        </template>
      </CLogo>
    </v-col>
    <v-col cols="12" xl="7" class="mt-12 mb-12 pb-12">
      <v-row class="p-relative" id="scene_vehicule" no-gutters>
        <v-btn @click="toggleFullScreen" class="btn-fullscreen" color="deep-purple darken-3" fab>
          <v-icon>{{ `${!isFullscreen ? "mdi-fullscreen" : "mdi-fullscreen-exit"}` }}</v-icon></v-btn
        >
        <transition name="fade" mode="out-in">
          <SceneVehicule
            v-if="displaySceneVehicule"
            :url_image="samples && samples.photoList && samples.photoList[0] ? samples.photoList[0] : null"
            :display_top_title="$vuetify.breakpoint.mdAndUp"
            :car_data="samples"
            :loyer="loyer"
            :loyer_duration="Number(loyer_duration)"
            :loyer_taeg="Number(loyer_taeg) / 100"
            :detailsCar_overImage="detailsCar_overImage"
            :detailsCar_right="detailsCar_right"
            :smallDetailsCar="smallDetailsCar"
            :duration="0"
            v-on:vehicule-end="onVehiculeEnd"
            v-on:on-image-error="onImageError"
            ><template v-slot:additional-absolute>
              <!-- <Logo key="company" :logo="logo_company" topLeft /> -->

              <Logo
                key="company"
                :logo="logo_url"
                v-on:on-image-error="onCompanyLogoError"
                :class="{ 'pt-10': smallDetailsCar }"
                topLeft
              />
              <Logo
                key="mb"
                :logo="require('@/assets/logo/stamp white.svg')"
                :bottomRight="!detailsCar_right"
                :bottomLeft="detailsCar_right"
                opacity
              />
            </template>
          </SceneVehicule>
        </transition>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import CLogo from "@/components/Diapo/Options/Inputs/Logo";
import Logo from "@/components/Diapo/Logo/Logo.vue";

import SceneVehicule from "@/components/Scenes/SceneVehicule";

import snackBar from "@/utils/mixins/snackBar";
import mixin_logo from "@/utils/mixins/logo";

import screenfull from "screenfull";

export default {
  name: "BLogo",

  components: {
    Logo,
    CLogo,
    SceneVehicule,
  },

  mixins: [snackBar, mixin_logo],

  props: {
    samples: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: null,
    },
    company_name: {
      type: null,
    },
    loyer: {
      type: null,
    },
    loyer_duration: {
      type: null,
    },
    loyer_taeg: {
      type: null,
    },
    display_details_car: {
      type: null,
    },
    detailsCar_overImage: {
      type: null,
    },
    detailsCar_right: {
      type: null,
    },
    smallDetailsCar: {
      type: null,
    },
  },
  data() {
    return {
      displaySceneVehicule: true,

      logo_url: null,
      /** End of Photo */
      isFullscreen: false,
    };
  },

  mounted() {
    if (screenfull.isEnabled) {
      screenfull.on("change", this.onFullScreenChange);
    }
  },

  beforeDestroy() {
    if (screenfull.isEnabled) {
      screenfull.off("change", this.onFullScreenChange);
    }
  },

  methods: {
    onCompanyLogoError() {
      this.displaySuccessSnack(`Impossible de charger le logo. Si le problème persiste, veuillez contacter le support.`);
    },

    async onVehiculeEnd() {
      this.displaySceneVehicule = false;
      await this.$nextTick();
      await new Promise((resolved) => setTimeout(resolved, 1000));
      this.displaySceneVehicule = true;
    },

    async onImageError() {
      this.onVehiculeEnd();
    },

    onFullScreenChange() {
      this.isFullscreen = !this.isFullscreen;
    },

    toggleFullScreen() {
      const element = document.getElementById("scene_vehicule");

      if (screenfull.isEnabled) {
        screenfull.toggle(element);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-fullscreen {
  z-index: 6;
  opacity: 0.5;
  top: 20px;
  left: 20px;
  position: absolute;
}
</style>
