<template>
  <v-container class="pt-15" fill-height>
    <SceneOptions />
  </v-container>
</template>

<script>
import SceneOptions from "@/components/Scenes/SceneOptions";
export default {
  name: "DiapoOptions",

  components: { SceneOptions },
};
</script>

<style lang="scss" scoped></style>
