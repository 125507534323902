var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","lg":"7"}},[_c('v-checkbox',{attrs:{"input-value":_vm.local,"label":"Météo locale","color":"success"},on:{"change":(val) => {
          _vm.$emit('update:local', val);
        }}})],1),_c('v-col',{attrs:{"cols":"12","lg":"7"}},[_c('v-checkbox',{attrs:{"input-value":_vm.regional,"label":"Météo régionale","color":"success"},on:{"change":(val) => {
          _vm.$emit('update:regional', val);
        }}})],1),_c('v-col',{attrs:{"cols":"12","lg":"7"}},[_c('v-checkbox',{attrs:{"input-value":_vm.national,"label":"Météo nationale","color":"success"},on:{"change":(val) => {
          _vm.$emit('update:national', val);
        }}})],1),_c('v-expand-transition',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.national || _vm.regional || _vm.local),expression:"national || regional || local"}],staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"justify-center",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","lg":"7"}},[_c('v-text-field',{attrs:{"filled":"","rules":_vm.rulesIntervalWeather,"value":_vm.interval,"suffix":`minute${_vm.interval ? (parseInt(_vm.interval) > 1 ? 's' : '') : 's'} d'interval entre chaque méteo`,"placeholder":"20","persistent-hint":"","label":"Fréquence d'apparition de la météo en minute","hint":"Par défaut : toutes les 20 minutes. Laisser ce champs vide pour faire apparaître la météo toutes les 20 minutes."},on:{"change":(val) => {
                _vm.$emit('update:interval', val);
              }}})],1),_c('v-col',{attrs:{"cols":"12","lg":"7"}},[_c('v-text-field',{attrs:{"filled":"","rules":_vm.rulesDurationWeather,"value":_vm.duration,"suffix":`seconde${_vm.duration ? (parseInt(_vm.duration) > 1 ? 's' : '') : 's'} d'affichage de la météo`,"placeholder":"15","persistent-hint":"","label":"Durée d'affichage de la météo en seconde","hint":"Par défaut : 15 secondes. Laisser ce champs vide pour afficher la météo pendant 15 secondes."},on:{"change":(val) => {
                _vm.$emit('update:duration', val);
              }}})],1),_vm._t("additonal-options")],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }