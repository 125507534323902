<template>
  <v-text-field
    v-show="cSlides_list.length > 0"
    filled
    :rules="rulesSlideCustom"
    :value="frequency_cSlides"
    :disabled="cSlides_list.length <= 0"
    placeholder="5"
    persistent-hint
    :suffix="
      `${frequency_cSlides ? (parseInt(frequency_cSlides) > 1 ? 'voitures' : 'voiture ') : 'voitures'} entre chaque publicité`
    "
    label="Fréquence d'apparation des slides personnalisées"
    hint="Par défaut : toutes les 5 voitures. Laisser ce champs vide pour faire défiler une à une chaque slide personnalisées sélectionnées toute les 5 voitures."
    @change="
      (val) => {
        $emit('update:frequency_cSlides', val);
      }
    "
  ></v-text-field>
</template>

<script>
export default {
  name: "SlidesFrequency",

  props: ["frequency_cSlides", "cSlides_list"],

  data() {
    return {
      rulesSlideCustom: [
        (value) => {
          if (value != "" && /^(-|\+)?(\d+|Infinity)$/.test(value) == false)
            return "Ce champs ne doit comporter que des chiffres";
          return true;
        },
        (value) => {
          if (value != "" && Number(value) < 1) return "L'interval entre chaque publicité ne peut pas être inférieur à 1 voiture";
          return true;
        },
        (value) => {
          if (value != "" && Number(value) > 25)
            return "L'interval entre chaque publicité ne peut pas être supérieur à 25 voiture";
          return true;
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
