var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"align-start justify-center fill-height",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mt-10 pt-10",attrs:{"cols":"11","xl":"5"}},[_c('CWeather',{staticClass:"justify-center",attrs:{"local":_vm.local,"regional":_vm.regional,"national":_vm.national,"duration":_vm.duration,"interval":_vm.interval},on:{"update:local":(val) => {
          _vm.$emit('update:local', val);
        },"update:regional":(val) => {
          _vm.$emit('update:regional', val);
        },"update:national":(val) => {
          _vm.$emit('update:national', val);
        },"update:duration":(val) => {
          _vm.$emit('update:duration', val);
        },"update:interval":(val) => {
          _vm.$emit('update:interval', val);
        }},scopedSlots:_vm._u([{key:"additonal-options",fn:function(){return [_c('v-col',{attrs:{"cols":"12","lg":"7"}},[_c('v-btn',{staticClass:"mt-10",attrs:{"color":"deep-purple darken-3","block":""},on:{"click":_vm.toggleFullScreen}},[_vm._v(" "+_vm._s(`${!_vm.isFullscreen ? "Activer le mode plein écran" : "Désactiver le mode plein écran"}`))])],1)]},proxy:true}])})],1),_c('v-col',{staticClass:"mt-12 mb-12 pb-12",attrs:{"cols":"12","xl":"7"}},[_c('v-row',{staticClass:"p-relative",attrs:{"id":"scene_weather","no-gutters":""}},[(_vm.local || _vm.regional || _vm.national)?_c('v-btn',{staticClass:"btn-fullscreen",attrs:{"color":"deep-purple darken-3","fab":""},on:{"click":_vm.toggleFullScreen}},[_c('v-icon',[_vm._v(_vm._s(`${!_vm.isFullscreen ? "mdi-fullscreen" : "mdi-fullscreen-exit"}`))])],1):_vm._e(),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.local || _vm.regional || _vm.national)?_c('SceneWeather',{attrs:{"show":_vm.ready_weather,"intervalOnMounted":false,"interval":0,"duration":Number(_vm.duration) * 1000,"ref_weather":_vm.ref_weather},on:{"weather-end":_vm.handleEnd}}):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }