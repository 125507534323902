<template>
  <v-row>
    <v-col cols="12" lg="7">
      <v-checkbox
        :input-value="trafic"
        label="Trafic routier"
        color="success"
        @change="
          (val) => {
            $emit('update:trafic', val);
          }
        "
      >
      </v-checkbox>
    </v-col>

    <v-expand-transition>
      <v-col v-show="trafic" cols="12" class="pa-0">
        <v-row class="justify-center" no-gutters>
          <v-col cols="12" lg="7">
            <v-text-field
              filled
              :rules="rulesIntervalTrafic"
              :value="interval"
              :suffix="
                `minute${interval ? (parseInt(interval) > 1 ? 's' : '') : 's'} d'interval entre chaque carte du trafic routier`
              "
              placeholder="15"
              persistent-hint
              label="Fréquence d'apparition du trafic routier en minute"
              hint="Par défaut : toutes les 15 minutes. Laisser ce champs vide pour faire apparaître le trafic routier toutes les 15 minutes."
              @change="
                (val) => {
                  $emit('update:interval', val);
                }
              "
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="7">
            <v-text-field
              filled
              :rules="rulesDurationTrafic"
              :value="duration"
              :suffix="`seconde${duration ? (parseInt(duration) > 1 ? 's' : '') : 's'} d'affichage du trafic routier`"
              placeholder="15"
              persistent-hint
              label="Durée d'affichage du trafic routier en seconde"
              hint="Par défaut : 15 secondes. Laisser ce champs vide pour afficher le trafic routier pendant 15 secondes."
              @change="
                (val) => {
                  $emit('update:duration', val);
                }
              "
            ></v-text-field>
          </v-col>
          <slot name="additonal-options"></slot>
        </v-row>
      </v-col>
    </v-expand-transition>
  </v-row>
</template>

<script>
import user from "@/utils/mixins/user";

export default {
  name: "CTrafic",

  props: ["trafic", "interval", "duration"],

  mixins: [user],

  data() {
    return {
      rulesIntervalTrafic: [
        (value) => {
          if (value != "" && /^(-|\+)?(\d+|Infinity)$/.test(value) == false)
            return "Ce champs ne doit comporter que des chiffres";
          return true;
        },
        (value) => {
          if (value != "" && Number(value) < 1)
            return "L'interval d'apparition du trafic routier ne peut pas être inférieur à 1 minute";
          return true;
        },
      ],
      rulesDurationTrafic: [
        (value) => {
          if (value != "" && /^(-|\+)?(\d+|Infinity)$/.test(value) == false)
            return "Ce champs ne doit comporter que des chiffres";
          return true;
        },
        (value) => {
          if (value != "" && Number(value) < 3)
            return "Le nombre de seconde pendant lequel le trafic routier est affiché ne peut pas être inférieur à 3 secondes";
          return true;
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
