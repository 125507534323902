<template>
  <v-row class="align-start justify-center fill-height" no-gutters>
    <v-col class="mt-10 pt-10" cols="11" xl="5">
      <CPublicite
        class="justify-center"
        :concession_cSlides_list="concession_cSlides_list"
        :groupe_cSlides_list="groupe_cSlides_list"
        :franchise_cSlides_list="franchise_cSlides_list"
        :concession_frequency_cSlides="concession_frequency_cSlides"
        :groupe_frequency_cSlides="groupe_frequency_cSlides"
        :franchise_frequency_cSlides="franchise_frequency_cSlides"
        v-on:update:concession_cSlides_list="
          (val) => {
            $emit('update:concession_cSlides_list', val);
          }
        "
        v-on:update:groupe_cSlides_list="
          (val) => {
            $emit('update:groupe_cSlides_list', val);
          }
        "
        v-on:update:franchise_cSlides_list="
          (val) => {
            $emit('update:franchise_cSlides_list', val);
          }
        "
        v-on:update:concession_frequency_cSlides="
          (val) => {
            $emit('update:concession_frequency_cSlides', val);
          }
        "
        v-on:update:groupe_frequency_cSlides="
          (val) => {
            $emit('update:groupe_frequency_cSlides', val);
          }
        "
        v-on:update:franchise_frequency_cSlides="
          (val) => {
            $emit('update:franchise_frequency_cSlides', val);
          }
        "
      >
        <template v-slot:additonal-options>
          <v-row class="justify-center pb-10" no-gutters>
            <v-col cols="12" lg="7">
              <v-btn @click="toggleFullScreen" color="deep-purple darken-3" class="mt-10" block>
                {{ `${!isFullscreen ? "Activer le mode plein écran" : "Désactiver le mode plein écran"}` }}</v-btn
              >
            </v-col>
          </v-row>
        </template>
      </CPublicite>
    </v-col>
    <v-col cols="12" xl="7" class="mt-12 mb-12 pb-12">
      <v-row class="p-relative" id="scene_publicite" no-gutters>
        <v-row class="p-absolute" no-gutters>
          <v-btn @click="toggleFullScreen" class="btn-fullscreen" color="deep-purple darken-3" fab>
            <v-icon>{{ `${!isFullscreen ? "mdi-fullscreen" : "mdi-fullscreen-exit"}` }}</v-icon></v-btn
          >
          <v-btn @click="debounced_onPubEnds" class="btn-floating" color="deep-purple darken-3" fab>
            <v-icon>{{ `mdi-skip-forward` }}</v-icon></v-btn
          >
        </v-row>

        <!-- debounce leading false -->
        <transition name="fade" mode="out-in">
          <v-responsive :aspect-ratio="1.77">
            <ScenePublicite
              v-if="pub_url && pub_type"
              v-on:on-publicite-end="onPubEnds"
              v-on:on-image-error="onPubEnds"
              :type="pub_type"
              :url="pub_url"
              :duration_image_publicite="pub_duration"
              :hide_cursor="false"
            />
          </v-responsive>
        </transition>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import CPublicite from "@/components/Diapo/Options/Inputs/Publicite";
import ScenePublicite from "@/components/Scenes/ScenePublicite";

import screenfull from "screenfull";

import snackBar from "@/utils/mixins/snackBar";
import { debounce } from "lodash";

export default {
  props: [
    "concession_cSlides_list",
    "groupe_cSlides_list",
    "franchise_cSlides_list",
    "concession_frequency_cSlides",
    "groupe_frequency_cSlides",
    "franchise_frequency_cSlides",
  ],

  components: {
    CPublicite,
    ScenePublicite,
  },

  mixins: [snackBar],

  data() {
    return {
      /**
       * screen
       */
      isFullscreen: false,

      /**
       * selected
       */
      selected_concession_cSlides: [],
      selected_groupe_cSlides: [],
      selected_franchise_cSlides: [],

      /**
       * general
       */

      map_specific_type_slide: {
        concession: this.getSpecificConcessionSlide,
        groupe: this.getSpecificGroupeSlide,
        franchise: this.getSpecificFranchiseSlide,
      },
      current_publicite: null,
      merged_selected_cSlides: [],
      pub_index: -1,

      /**
       * pub parameter
       */

      pub_type: null,
      pub_url: null,
      pub_duration: 7000,
    };
  },

  mounted() {
    if (screenfull.isEnabled) {
      screenfull.on("change", this.onFullScreenChange);
    }
  },

  beforeDestroy() {
    if (screenfull.isEnabled) {
      screenfull.off("change", this.onFullScreenChange);
    }
  },

  methods: {
    onFullScreenChange() {
      this.isFullscreen = !this.isFullscreen;
    },

    toggleFullScreen() {
      const element = document.getElementById("scene_publicite");

      if (screenfull.isEnabled) {
        screenfull.toggle(element);
      }
    },
    debounced_onPubEnds: debounce(
      async function() {
        await this.onPubEnds();
      },
      700,
      { trailing: true, leading: false }
    ),
    async onPubEnds() {
      this.pub_url = null;
      this.pub_type = null;
      await this.$nextTick();
      await new Promise((resolved) => setTimeout(resolved, 1000));
      await this.nextPub();
    },

    async nextPub() {
      try {
        ++this.pub_index;

        if (this.pub_index >= this.merged_selected_cSlides.length) {
          this.pub_index = 0;
        }

        if (this.merged_selected_cSlides.length > 0) {
          if (this.merged_selected_cSlides[this.pub_index].company_type === "concession")
            this.current_publicite = await this.map_specific_type_slide[
              this.merged_selected_cSlides[this.pub_index].company_type
            ](this.merged_selected_cSlides[this.pub_index].slide_id);
          else
            this.current_publicite = await this.map_specific_type_slide[
              this.merged_selected_cSlides[this.pub_index].company_type
            ](this.merged_selected_cSlides[this.pub_index].company_name, this.merged_selected_cSlides[this.pub_index].slide_id);
          this.pub_url = this.current_publicite.url;
          this.pub_type = this.current_publicite.format == "video" ? "video" : "image";
        }
      } catch (error) {
        console.error(error);
        // this.displayErrorSnack(error);
        // this.convertSelectedSlides();
        await this.nextPub();
      }
    },

    async convertSelectedSlides() {
      this.merged_selected_cSlides = []
        .concat(this.selected_concession_cSlides)
        .concat(this.selected_groupe_cSlides)
        .concat(this.selected_franchise_cSlides);
      if (this.merged_selected_cSlides.length > 0) await this.nextPub();
      else {
        this.pub_url = null;
        this.pub_type = null;
      }
    },

    /**
     * Convert
     */
    async convertSelectedConcessionSlides() {
      console.log("convertSelectedConcessionSlides");
      if (this.concession_cSlides_list === "all") {
        this.selected_concession_cSlides = (await this.getAllConcessionSlides()).map((c) => ({
          company_type: "concession",
          company_name: null,
          slide_id: c._id,
        }));
      } else if (Array.isArray(this.concession_cSlides_list) && this.concession_cSlides_list.length > 0) {
        this.selected_concession_cSlides = this.concession_cSlides_list.map((c) => ({
          company_type: "concession",
          company_name: null,
          slide_id: c,
        }));
      } else {
        this.selected_concession_cSlides = [];
      }
    },

    async convertSelectedGroupeSlides() {
      if (this.groupe_cSlides_list === "all") {
        this.selected_groupe_cSlides = (await this.getAllGroupeSlides())
          .map((el) => ({
            company_type: "groupe",
            company_name: el.company_name,
            slide_id: el.custom_slide.map((c) => c._id),
          }))
          .reduce((acc, gr) => {
            return acc.concat(gr.slide_id.map((el) => ({ company_type: "groupe", company_name: gr.company_name, slide_id: el })));
          }, []);
      } else if (Array.isArray(this.groupe_cSlides_list) && this.groupe_cSlides_list.length > 0) {
        this.selected_groupe_cSlides = this.groupe_cSlides_list.reduce((acc, gr) => {
          return acc.concat(gr.slides.map((el) => ({ company_type: "groupe", company_name: gr.name, slide_id: el })));
        }, []);
      } else {
        this.selected_groupe_cSlides = [];
      }
    },

    async convertSelectedFranchiseSlides() {
      if (this.franchise_cSlides_list === "all") {
        this.selected_franchise_cSlides = (await this.getAllFranchiseSlides())
          .map((el) => ({
            company_type: "franchise",
            company_name: el.company_name,
            slide_id: el.custom_slide.map((c) => c._id),
          }))
          .reduce((acc, fr) => {
            return acc.concat(
              fr.slide_id.map((el) => ({ company_type: "franchise", company_name: fr.company_name, slide_id: el }))
            );
          }, []);
      } else if (Array.isArray(this.franchise_cSlides_list) && this.franchise_cSlides_list.length > 0) {
        this.selected_franchise_cSlides = this.franchise_cSlides_list.reduce((acc, fr) => {
          return acc.concat(fr.slides.map((el) => ({ company_type: "franchise", company_name: fr.name, slide_id: el })));
        }, []);
      } else {
        this.selected_franchise_cSlides = [];
      }
    },

    /**
     * Get Slides
     */

    async getAllFranchiseSlides() {
      let data = [];
      try {
        data = (await this.$axios.get("/franchise/custom_slide"))?.data;
      } catch (error) {
        console.error(error);
        this.displayErrorSnack(error);
      }
      return data;
    },

    async getAllGroupeSlides() {
      let data = [];
      try {
        data = (await this.$axios.get("/groupe/custom_slide"))?.data;
      } catch (error) {
        console.error(error);
        this.displayErrorSnack(error);
      }
      return data;
    },

    async getAllConcessionSlides() {
      let data = [];
      try {
        data = (await this.$axios.get("/concession/custom_slide")).data;
      } catch (error) {
        console.error(error);
        this.displayErrorSnack(error);
      }
      return data;
    },

    async getSpecificConcessionSlide(slide_id) {
      let data = null;
      try {
        data = (
          await this.$axios.get("/concession/custom_slide", {
            params: {
              slide_id,
            },
          })
        ).data;
      } catch (error) {
        console.error(error);
        // this.displayErrorSnack(error);
      }
      return data;
    },

    async getSpecificGroupeSlide(groupe_name, slide_id) {
      let data = null;
      try {
        data = (
          await this.$axios.get("/groupe/custom_slide", {
            params: {
              groupe_name,
              slide_id,
            },
          })
        ).data;
      } catch (error) {
        console.error(error);
        // this.displayErrorSnack(error);
      }
      return data;
    },

    async getSpecificFranchiseSlide(franchise_name, slide_id) {
      let data = null;
      try {
        data = (
          await this.$axios.get("/franchise/custom_slide", {
            params: {
              franchise_name,
              slide_id,
            },
          })
        ).data;
      } catch (error) {
        console.error(error);
        // this.displayErrorSnack(error);
      }
      return data;
    },
  },

  watch: {
    concession_cSlides_list: {
      handler: debounce(
        async function() {
          console.log("icicicicicicici", JSON.stringify(this.concession_cSlides_list));
          await this.convertSelectedConcessionSlides();
          this.convertSelectedSlides();
        },
        1500,
        { trailing: true, leading: false }
      ),
      // immediate: true,
    },
    groupe_cSlides_list: {
      handler: debounce(
        async function() {
          await this.convertSelectedGroupeSlides();
          this.convertSelectedSlides();
        },
        1500,
        { trailing: true, leading: false }
      ),
      // immediate: true,
      deep: true,
    },
    franchise_cSlides_list: {
      handler: debounce(
        async function() {
          await this.convertSelectedFranchiseSlides();
          this.convertSelectedSlides();
        },
        1500,
        { trailing: true, leading: false }
      ),
      // immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-fullscreen {
  z-index: 6;
  opacity: 0.5;
}
.btn-floating {
  z-index: 6;
  opacity: 0.5;
}
</style>
