var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"align-start justify-center fill-height",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mt-10 pt-10",attrs:{"cols":"11","xl":"5"}},[_c('CPhotos',{staticClass:"justify-center",attrs:{"nbr_second_per_photo":_vm.nbr_second_per_photo,"max_photo_per_vehicule":_vm.max_photo_per_vehicule,"display_details_car":_vm.display_details_car,"detailsCar_overImage":_vm.detailsCar_overImage,"detailsCar_right":_vm.detailsCar_right,"smallDetailsCar":_vm.smallDetailsCar},on:{"update:nbr_second_per_photo":(val) => {
          _vm.$emit('update:nbr_second_per_photo', val);
        },"update:max_photo_per_vehicule":(val) => {
          _vm.$emit('update:max_photo_per_vehicule', val);
        },"update:display_details_car":(val) => {
          _vm.$emit('update:display_details_car', val);
        },"update:detailsCar_overImage":(val) => {
          _vm.$emit('update:detailsCar_overImage', val);
        },"update:detailsCar_right":(val) => {
          _vm.$emit('update:detailsCar_right', val);
        },"update:smallDetailsCar":(val) => {
          _vm.$emit('update:smallDetailsCar', val);
        }},scopedSlots:_vm._u([{key:"additonal-options",fn:function(){return [_c('v-col',{attrs:{"cols":"12","lg":"7"}},[_c('v-btn',{staticClass:"mt-10",attrs:{"color":"deep-purple darken-3","block":""},on:{"click":_vm.toggleFullScreen}},[_vm._v(" "+_vm._s(`${!_vm.isFullscreen ? "Activer le mode plein écran" : "Désactiver le mode plein écran"}`))])],1)]},proxy:true}])})],1),_c('v-col',{staticClass:"mt-12 mb-12 pb-12",attrs:{"cols":"12","xl":"7"}},[_c('v-row',{staticClass:"p-relative",attrs:{"id":"scene_vehicule","no-gutters":""}},[_c('v-btn',{staticClass:"btn-fullscreen",attrs:{"color":"deep-purple darken-3","fab":""},on:{"click":_vm.toggleFullScreen}},[_c('v-icon',[_vm._v(_vm._s(`${!_vm.isFullscreen ? "mdi-fullscreen" : "mdi-fullscreen-exit"}`))])],1),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.displaySceneVehicule)?_c('SceneVehicule',{attrs:{"url_image":_vm.samples && _vm.samples.photoList && _vm.samples.photoList[0] ? _vm.samples.photoList[0] : null,"display_top_title":_vm.$vuetify.breakpoint.mdAndUp,"car_data":_vm.samples,"loyer":_vm.loyer,"loyer_duration":Number(_vm.loyer_duration),"loyer_taeg":Number(_vm.loyer_taeg) / 100,"display_details_car":_vm.display_details_car,"detailsCar_overImage":_vm.detailsCar_overImage,"detailsCar_right":_vm.detailsCar_right,"smallDetailsCar":_vm.smallDetailsCar,"duration":Number(_vm.nbr_second_per_photo) * 1000},on:{"vehicule-end":_vm.onVehiculeEnd,"on-image-error":_vm.onImageError}}):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }