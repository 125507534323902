<template>
  <v-col class="treeview border-radius-1">
    <div
      class="pa-5 border-radius-1"
      :class="{
        'treeview-bg': is_selected,
        pointer: validation,
        'not-allowed': !validation,
      }"
      style="width: 100%"
      @click="$emit('click')"
    >
      <v-icon>{{ validation ? (is_selected ? "mdi-menu-down" : "mdi-menu-right") : "" }}</v-icon>
      {{ title }}
    </div>
    <v-slide-y-transition>
      <v-col v-show="is_selected">
        <slot></slot>
      </v-col>
    </v-slide-y-transition>
  </v-col>
</template>

<script>
export default {
  name: "TreeView",

  props: {
    title: { type: String, default: "" },
    validation: { type: Boolean, default: false },
    is_selected: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
.border-radius-1 {
  border-radius: 14px;
}

.treeview {
}

.treeview:hover {
  background-color: #5454547e;
}

.treeview-bg {
  background-color: #545454;
}
</style>
