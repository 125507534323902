var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"align-start justify-center fill-height",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mt-10 pt-10",attrs:{"cols":"11","xl":"5"}},[_c('CFilters',{staticClass:"justify-center",attrs:{"request_extra_done":_vm.request_extra_done,"full_list_codes":_vm.full_list_codes,"full_list_constructor":_vm.full_list_constructor,"full_list_qualification":_vm.full_list_qualification,"full_list_genre":_vm.full_list_genre,"selected_cardiff_codes":_vm.selected_cardiff_codes,"selected_constructors":_vm.selected_constructors,"selected_qualifications":_vm.selected_qualifications,"selected_genres":_vm.selected_genres},on:{"update:selected_cardiff_codes":(val) => {
          _vm.$emit('update:selected_cardiff_codes', val);
        },"update:selected_constructors":(val) => {
          _vm.$emit('update:selected_constructors', val);
        },"update:selected_qualifications":(val) => {
          _vm.$emit('update:selected_qualifications', val);
        },"update:selected_genres":(val) => {
          _vm.$emit('update:selected_genres', val);
        }}})],1),_c('v-col',{staticClass:"mt-12 mb-12 pb-12",attrs:{"cols":"12","xl":"7"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }