var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"align-start justify-center fill-height",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mt-10 pt-10",attrs:{"cols":"11","xl":"5"}},[_c('CLogo',{staticClass:"justify-center",attrs:{"type":_vm.type,"company_name":_vm.company_name},on:{"update:type":(val) => {
          _vm.$emit('update:type', val);
        },"update:company_name":(val) => {
          _vm.$emit('update:company_name', val);
        },"logo_link":(val) => {
          _vm.logo_url = val;
        }},scopedSlots:_vm._u([{key:"additonal-options",fn:function(){return [_c('v-col',{attrs:{"cols":"12","lg":"7"}},[_c('v-btn',{staticClass:"mt-10",attrs:{"color":"deep-purple darken-3","block":""},on:{"click":_vm.toggleFullScreen}},[_vm._v(" "+_vm._s(`${!_vm.isFullscreen ? "Activer le mode plein écran" : "Désactiver le mode plein écran"}`))])],1)]},proxy:true}])})],1),_c('v-col',{staticClass:"mt-12 mb-12 pb-12",attrs:{"cols":"12","xl":"7"}},[_c('v-row',{staticClass:"p-relative",attrs:{"id":"scene_vehicule","no-gutters":""}},[_c('v-btn',{staticClass:"btn-fullscreen",attrs:{"color":"deep-purple darken-3","fab":""},on:{"click":_vm.toggleFullScreen}},[_c('v-icon',[_vm._v(_vm._s(`${!_vm.isFullscreen ? "mdi-fullscreen" : "mdi-fullscreen-exit"}`))])],1),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.displaySceneVehicule)?_c('SceneVehicule',{attrs:{"url_image":_vm.samples && _vm.samples.photoList && _vm.samples.photoList[0] ? _vm.samples.photoList[0] : null,"display_top_title":_vm.$vuetify.breakpoint.mdAndUp,"car_data":_vm.samples,"loyer":_vm.loyer,"loyer_duration":Number(_vm.loyer_duration),"loyer_taeg":Number(_vm.loyer_taeg) / 100,"detailsCar_overImage":_vm.detailsCar_overImage,"detailsCar_right":_vm.detailsCar_right,"smallDetailsCar":_vm.smallDetailsCar,"duration":0},on:{"vehicule-end":_vm.onVehiculeEnd,"on-image-error":_vm.onImageError},scopedSlots:_vm._u([{key:"additional-absolute",fn:function(){return [_c('Logo',{key:"company",class:{ 'pt-10': _vm.smallDetailsCar },attrs:{"logo":_vm.logo_url,"topLeft":""},on:{"on-image-error":_vm.onCompanyLogoError}}),_c('Logo',{key:"mb",attrs:{"logo":require('@/assets/logo/stamp white.svg'),"bottomRight":!_vm.detailsCar_right,"bottomLeft":_vm.detailsCar_right,"opacity":""}})]},proxy:true}],null,false,2077259876)}):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }