<template>
  <v-row no-gutters>
    <v-col cols="12" lg="7">
      <v-checkbox
        :input-value="local"
        label="Météo locale"
        color="success"
        @change="
          (val) => {
            $emit('update:local', val);
          }
        "
      ></v-checkbox>
    </v-col>
    <v-col cols="12" lg="7">
      <v-checkbox
        :input-value="regional"
        label="Météo régionale"
        color="success"
        @change="
          (val) => {
            $emit('update:regional', val);
          }
        "
      ></v-checkbox>
    </v-col>
    <v-col cols="12" lg="7">
      <v-checkbox
        :input-value="national"
        label="Météo nationale"
        color="success"
        @change="
          (val) => {
            $emit('update:national', val);
          }
        "
      ></v-checkbox>
    </v-col>

    <v-expand-transition>
      <v-col v-show="national || regional || local" cols="12" class="pa-0">
        <v-row class="justify-center" no-gutters>
          <v-col cols="12" lg="7">
            <v-text-field
              filled
              :rules="rulesIntervalWeather"
              :value="interval"
              :suffix="`minute${interval ? (parseInt(interval) > 1 ? 's' : '') : 's'} d'interval entre chaque méteo`"
              placeholder="20"
              persistent-hint
              label="Fréquence d'apparition de la météo en minute"
              hint="Par défaut : toutes les 20 minutes. Laisser ce champs vide pour faire apparaître la météo toutes les 20 minutes."
              @change="
                (val) => {
                  $emit('update:interval', val);
                }
              "
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="7">
            <v-text-field
              filled
              :rules="rulesDurationWeather"
              :value="duration"
              :suffix="`seconde${duration ? (parseInt(duration) > 1 ? 's' : '') : 's'} d'affichage de la météo`"
              placeholder="15"
              persistent-hint
              label="Durée d'affichage de la météo en seconde"
              hint="Par défaut : 15 secondes. Laisser ce champs vide pour afficher la météo pendant 15 secondes."
              @change="
                (val) => {
                  $emit('update:duration', val);
                }
              "
            ></v-text-field>
          </v-col>
          <slot name="additonal-options"></slot>
        </v-row>
      </v-col>
    </v-expand-transition>
  </v-row>
</template>

<script>
import user from "@/utils/mixins/user";

export default {
  name: "CWeather",
  props: ["local", "regional", "national", "interval", "duration"],
  mixins: [user],

  data() {
    return {
      rulesIntervalWeather: [
        (value) => {
          if (value != "" && /^(-|\+)?(\d+|Infinity)$/.test(value) == false)
            return "Ce champs ne doit comporter que des chiffres";
          return true;
        },
        (value) => {
          if (value != "" && Number(value) < 1)
            return "L'interval d'apparition de la météo ne peut pas être inférieur à 1 minute";
          return true;
        },
      ],
      rulesDurationWeather: [
        (value) => {
          if (value != "" && /^(-|\+)?(\d+|Infinity)$/.test(value) == false)
            return "Ce champs ne doit comporter que des chiffres";
          return true;
        },
        (value) => {
          if (value != "" && Number(value) < 3)
            return "Le nombre de seconde pendant lequel la météo est affiché ne peut pas être inférieur à 3 secondes";
          return true;
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
