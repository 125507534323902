<template>
  <v-row class="align-start justify-center fill-height" no-gutters>
    <v-col class="mt-10 pt-10" cols="11" xl="5">
      <CFilters
        class="justify-center"
        :request_extra_done="request_extra_done"
        :full_list_codes="full_list_codes"
        :full_list_constructor="full_list_constructor"
        :full_list_qualification="full_list_qualification"
        :full_list_genre="full_list_genre"
        :selected_cardiff_codes="selected_cardiff_codes"
        :selected_constructors="selected_constructors"
        :selected_qualifications="selected_qualifications"
        :selected_genres="selected_genres"
        v-on:update:selected_cardiff_codes="
          (val) => {
            $emit('update:selected_cardiff_codes', val);
          }
        "
        v-on:update:selected_constructors="
          (val) => {
            $emit('update:selected_constructors', val);
          }
        "
        v-on:update:selected_qualifications="
          (val) => {
            $emit('update:selected_qualifications', val);
          }
        "
        v-on:update:selected_genres="
          (val) => {
            $emit('update:selected_genres', val);
          }
        "
      >
      </CFilters>
    </v-col>
    <v-col cols="12" xl="7" class="mt-12 mb-12 pb-12">
      <!-- TODO -->
    </v-col>
  </v-row>
</template>

<script>
import CFilters from "@/components/Diapo/Options/Inputs/Filtres";

export default {
  name: "BFilters",

  components: {
    CFilters,
  },

  props: ["selected_cardiff_codes", "selected_constructors", "selected_qualifications", "selected_genres"],

  data() {
    return {
      request_extra_done: false,
      full_list_codes: [],
      full_list_constructor: [],
      full_list_genre: [],
      full_list_qualification: [],
    };
  },

  mounted() {
    this.extraData();
  },

  methods: {
    async extraData() {
      try {
        this.request_extra_done = false;

        let data = (await this.$axios.get("/extra_data"))?.data;
        if (data?.codes) this.full_list_codes = data.codes;
        if (data?.constructors)
          this.full_list_constructor = data.constructors.map((el, index) => ({
            constructor_name: el.constructor_name,
            id: index,
          }));
        if (data?.cargenres) this.full_list_genre = data.cargenres;
        if (data?.carqualifications) this.full_list_qualification = data.carqualifications;
      } catch (error) {
        if (error?.response?.status != 401) {
          console.error(error);
          this.displayErrorSnack(error);
        }
      } finally {
        this.request_extra_done = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
