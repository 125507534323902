var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"7"}},[_c('v-checkbox',{attrs:{"input-value":_vm.trafic,"label":"Trafic routier","color":"success"},on:{"change":(val) => {
          _vm.$emit('update:trafic', val);
        }}})],1),_c('v-expand-transition',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.trafic),expression:"trafic"}],staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"justify-center",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","lg":"7"}},[_c('v-text-field',{attrs:{"filled":"","rules":_vm.rulesIntervalTrafic,"value":_vm.interval,"suffix":`minute${_vm.interval ? (parseInt(_vm.interval) > 1 ? 's' : '') : 's'} d'interval entre chaque carte du trafic routier`,"placeholder":"15","persistent-hint":"","label":"Fréquence d'apparition du trafic routier en minute","hint":"Par défaut : toutes les 15 minutes. Laisser ce champs vide pour faire apparaître le trafic routier toutes les 15 minutes."},on:{"change":(val) => {
                _vm.$emit('update:interval', val);
              }}})],1),_c('v-col',{attrs:{"cols":"12","lg":"7"}},[_c('v-text-field',{attrs:{"filled":"","rules":_vm.rulesDurationTrafic,"value":_vm.duration,"suffix":`seconde${_vm.duration ? (parseInt(_vm.duration) > 1 ? 's' : '') : 's'} d'affichage du trafic routier`,"placeholder":"15","persistent-hint":"","label":"Durée d'affichage du trafic routier en seconde","hint":"Par défaut : 15 secondes. Laisser ce champs vide pour afficher le trafic routier pendant 15 secondes."},on:{"change":(val) => {
                _vm.$emit('update:duration', val);
              }}})],1),_vm._t("additonal-options")],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }