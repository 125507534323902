<template>
  <v-row no-gutters>
    <v-col cols="12" lg="7">
      <v-checkbox
        :input-value="display_details_car"
        label="Afficher le détail des véhicules"
        color="success"
        @change="
          (val) => {
            $emit('update:display_details_car', val);
            if (!val) {
              $emit('update:detailsCar_overImage', true);
              $emit('update:detailsCar_right', false);
              $emit('update:smallDetailsCar', false);
            }
          }
        "
      ></v-checkbox>
    </v-col>

    <v-expand-transition>
      <v-col v-show="display_details_car" cols="12" class="pa-0">
        <v-row class="justify-center">
          <v-col cols="12" lg="7">
            <v-text-field
              filled
              suffix="secondes par photo"
              :value="nbr_second_per_photo"
              placeholder="7"
              persistent-hint
              label="Nombre de seconde par photo"
              hint="Par défaut : 7. Pour faire défiler une photo toute les 25 secondes taper 25."
              @change="
                (val) => {
                  if (val) $emit('update:nbr_second_per_photo', val);
                }
              "
            ></v-text-field>
          </v-col>

          <v-col cols="12" lg="7">
            <v-text-field
              filled
              suffix="photo(s) des véhicules"
              :value="max_photo_per_vehicule"
              placeholder="Toutes les"
              persistent-hint
              label="Nombre de photo par véhicule"
              hint="Par défaut : toutes. Laisser ce champs vide pour faire défiler toutes les photos de chaque véhicule."
              @change="
                (val) => {
                  if (val) $emit('update:max_photo_per_vehicule', val);
                }
              "
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="7">
            <!-- ne pas afficher le détail -->
            <!-- TODO -->
          </v-col>
          <v-col cols="12" lg="7">
            <v-checkbox
              :input-value="detailsCar_overImage === false"
              persistent-hint
              hint="Mettre côte à côte les détail du véhicule et la photo"
              label="Détails du véhicule à côté de la photo"
              color="success"
              @change="
                (val) => {
                  $emit('update:detailsCar_overImage', !val);
                  if (smallDetailsCar) $emit('update:smallDetailsCar', false);
                }
              "
            ></v-checkbox>
          </v-col>
          <v-col cols="12" lg="7">
            <v-checkbox
              :input-value="detailsCar_right"
              persistent-hint
              hint="Changer les détails véhicule de place"
              label="Détails du véhicule à droite"
              color="success"
              @change="
                (val) => {
                  $emit('update:detailsCar_right', val);
                  if (smallDetailsCar) $emit('update:smallDetailsCar', false);
                }
              "
            ></v-checkbox>
          </v-col>
          <v-col cols="12" lg="7">
            <v-checkbox
              :input-value="smallDetailsCar"
              persistent-hint
              hint="Choisir la version minimaliste du détails véhicule"
              label="Détails du voiture mode miniature"
              color="success"
              @change="
                (val) => {
                  $emit('update:smallDetailsCar', val);
                  if (val) {
                    $emit('update:detailsCar_overImage', true);
                    $emit('update:detailsCar_right', false);
                  }
                }
              "
            ></v-checkbox>
          </v-col>

          <slot name="additonal-options"></slot> </v-row></v-col
    ></v-expand-transition>
  </v-row>
</template>

<script>
import user from "@/utils/mixins/user";

export default {
  name: "CPhotos",

  props: [
    "nbr_second_per_photo",
    "max_photo_per_vehicule",
    "detailsCar_overImage",
    "detailsCar_right",
    "smallDetailsCar",
    "display_details_car",
  ],

  mixins: [user],

  data() {
    return {
      rulesSecondPerPhoto: [
        (value) => {
          if (value != "" && /^(-|\+)?(\d+|Infinity)$/.test(value) == false)
            return "Ce champs ne doit comporter que des chiffres";
          return true;
        },
        (value) => {
          if (value != "" && Number(value) < 3) return "Le nombre de seconde par photo ne doit pas être inférieur à 3";
          return true;
        },
      ],
      rulesMaxPhoto: [
        (value) => {
          if (value != "" && /^(-|\+)?(\d+|Infinity)$/.test(value) == false)
            return "Ce champs ne doit comporter que des chiffres";
          return true;
        },
        (value) => {
          if (value != "" && Number(value) < 1) return "Le nombre de photo par véhicule ne peut pas être inférieur à 1";
          return true;
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
