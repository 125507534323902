<template>
  <v-row class="align-start justify-center fill-height" no-gutters>
    <v-col class="mt-10 pt-10" cols="11" xl="5">
      <!-- v-on:update:local="
          (val) => {
            $emit('update:local', val);
          }
        " -->
      <CTrafic
        class="justify-center"
        :trafic="trafic"
        :duration="duration"
        :interval="interval"
        v-on:update:trafic="
          (val) => {
            $emit('update:trafic', val);
          }
        "
        v-on:update:duration="
          (val) => {
            $emit('update:duration', val);
          }
        "
        v-on:update:interval="
          (val) => {
            $emit('update:interval', val);
          }
        "
      >
        <template v-slot:additonal-options>
          <v-col cols="12" lg="7">
            <v-btn @click="toggleFullScreen" color="deep-purple darken-3" class="mt-10" block>
              {{ `${!isFullscreen ? "Activer le mode plein écran" : "Désactiver le mode plein écran"}` }}</v-btn
            ></v-col
          >
        </template>
      </CTrafic>
    </v-col>
    <v-col cols="12" xl="7" class="mt-12 mb-12 pb-12">
      <v-row class="p-relative" id="scene_trafic" no-gutters>
        <v-btn v-if="trafic" @click="toggleFullScreen" class="btn-fullscreen" color="deep-purple darken-3" fab>
          <v-icon>{{ `${!isFullscreen ? "mdi-fullscreen" : "mdi-fullscreen-exit"}` }}</v-icon></v-btn
        >
        <transition name="fade" mode="out-in">
          <!-- v-on:weather-ready="handleReady" -->
          <SceneTraffic
            v-if="show"
            :show="trafic"
            :interval="0"
            :intervalOnMounted="false"
            :duration="Number(duration) * 1000"
            v-on:traffic-end="debouncedhandleEnd"
          />
        </transition>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import CTrafic from "@/components/Diapo/Options/Inputs/Trafic";
import SceneTraffic from "@/components/Scenes/SceneTraffic";

import screenfull from "screenfull";

import { debounce } from "lodash";

export default {
  props: ["trafic", "duration", "interval"],

  components: {
    CTrafic,
    SceneTraffic,
  },

  data() {
    return {
      show: false,
      /**
       * screen
       */
      isFullscreen: false,
    };
  },

  mounted() {
    if (screenfull.isEnabled) {
      screenfull.on("change", this.onFullScreenChange);
    }
    this.show = this.trafic;
  },

  beforeDestroy() {
    if (screenfull.isEnabled) {
      screenfull.off("change", this.onFullScreenChange);
    }
  },

  methods: {
    /**
     * Using debounce to avoid spaming onShowChange while manipulating options
     */
    debouncedhandleEnd: debounce(async function() {
      await this.handleEnd();
    }, 1000),

    async handleEnd() {
      this.show = false;
      await this.$nextTick();
      await new Promise((resolved) => setTimeout(resolved, 1000));
      this.show = true;
    },

    onFullScreenChange() {
      this.isFullscreen = !this.isFullscreen;
    },

    toggleFullScreen() {
      const element = document.getElementById("scene_trafic");

      if (screenfull.isEnabled) {
        screenfull.toggle(element);
      }
    },
  },

  watch: {
    trafic: {
      handler: function(val) {
        this.show = val;
      },
    },
    duration: {
      handler: function(val) {
        this.debouncedhandleEnd();
      },
    },
    interval: {
      handler: function(val) {
        this.debouncedhandleEnd();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-fullscreen {
  z-index: 6;
  opacity: 0.5;
  top: 20px;
  left: 20px;
  position: absolute;
}
</style>
