var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"flex-column py-3",attrs:{"no-gutters":""}},[_vm._t("additonal-options"),_c('v-col',[_c('c-tree-view',{attrs:{"title":`Publicité de la concession`,"validation":Boolean(_vm.company_data.concession.name),"is_selected":_vm.ads_treeview[0].parent},on:{"click":function($event){_vm.company_data.concession.name && _vm.toggleParentPubliciteTree(0)}}},[_c('v-row',{attrs:{"justify":"space-around"}},[_c('ModeSlide',{attrs:{"frequency_cSlides":_vm.my_concession_frequency_cSlides,"cSlides_list":_vm.my_concession_cSlides_list,"mode":_vm.modeAllConcessionSlides,"label":!_vm.modeAllConcessionSlides ? 'Tout sélectionner' : 'Selection manuelle',"storedMode":"modeAllConcessionSlides","storedFrequency":"concession_frequency_cSlides"},on:{"update:frequency_cSlides":function($event){_vm.my_concession_frequency_cSlides=$event},"update:frequency_c-slides":function($event){_vm.my_concession_frequency_cSlides=$event},"modeChange":(val) => {
              _vm.modeAllConcessionSlides = val;
              _vm.onModeAllSlidesChange('concession', _vm.modeAllConcessionSlides);
            },"update:mode-options":(val) => {
              _vm.modeAllConcessionSlides = val;
              _vm.onModeAllSlidesChange('concession', _vm.modeAllConcessionSlides);
            }}})],1),(_vm.company_data.concession.name)?_c('Catalogue',{staticClass:"pt-9",attrs:{"company_type":"concession","company_name":_vm.company_data.concession.name,"custom_slide_list":_vm.company_data.concession.custom_slides,"selected_custom_slide_list":_vm.my_concession_cSlides_list,"removable":false,"selectable":!_vm.modeAllConcessionSlides,"highlight":_vm.modeAllConcessionSlides},on:{"selected":_vm.handleCatalogueSelectSlide,"on-img-error":_vm.catalogueConcession}}):_vm._e()],1),_c('c-tree-view',{attrs:{"title":`Publicités groupes`,"validation":Boolean(_vm.company_data.groupe && _vm.company_data.groupe.length > 0),"is_selected":_vm.ads_treeview[1].parent},on:{"click":function($event){_vm.company_data.groupe && _vm.company_data.groupe.length > 0 && _vm.toggleParentPubliciteTree(1)}}},[_c('ModeSlide',{attrs:{"frequency_cSlides":_vm.my_groupe_frequency_cSlides,"cSlides_list":_vm.my_groupe_cSlides_list,"mode":_vm.modeAllGroupeSlides,"label":!_vm.modeAllGroupeSlides ? 'Tout sélectionner' : 'Selection manuelle',"storedMode":"modeAllGroupeSlides","storedFrequency":"groupe_frequency_cSlides"},on:{"update:frequency_cSlides":function($event){_vm.my_groupe_frequency_cSlides=$event},"update:frequency_c-slides":function($event){_vm.my_groupe_frequency_cSlides=$event},"modeChange":(val) => {
            _vm.modeAllGroupeSlides = val;
            _vm.onModeAllSlidesChange('groupe', _vm.modeAllGroupeSlides);
          },"update:mode-options":(val) => {
            _vm.modeAllGroupeSlides = val;
            _vm.onModeAllSlidesChange('groupe', _vm.modeAllGroupeSlides);
          }}}),_vm._l((_vm.company_data.groupe),function(groupe,idx){return _c('v-col',{key:groupe.ref_groupe_by_id.name},[_c('c-tree-view',{attrs:{"title":groupe.ref_groupe_by_id.name,"validation":Boolean(groupe.ref_groupe_by_id.name),"is_selected":_vm.ads_treeview[1].children[idx]},on:{"click":function($event){_vm.company_data.groupe && _vm.company_data.groupe.length > 0 && _vm.toggleChildrenPubliciteTree(1, idx)}}},[_c('Catalogue',{staticClass:"pt-9",attrs:{"company_type":"groupe","company_name":groupe.ref_groupe_by_id.name,"custom_slide_list":groupe.ref_groupe_by_id.custom_slide,"selected_custom_slide_list":_vm.my_groupe_cSlides_list,"removable":false,"selectable":!_vm.modeAllGroupeSlides,"highlight":_vm.modeAllGroupeSlides},on:{"selected":_vm.handleCatalogueSelectSlide,"on-img-error":_vm.catalogueGroupe}})],1)],1)})],2),_c('c-tree-view',{attrs:{"title":"Publicités franchise","validation":Boolean(_vm.company_data.franchise && _vm.company_data.franchise.length > 0),"is_selected":_vm.ads_treeview[2].parent},on:{"click":function($event){_vm.company_data.franchise && _vm.company_data.franchise.length > 0 && _vm.toggleParentPubliciteTree(2)}}},[_c('ModeSlide',{attrs:{"frequency_cSlides":_vm.my_franchise_frequency_cSlides,"cSlides_list":_vm.my_franchise_cSlides_list,"mode":_vm.modeAllFranchiseSlides,"label":!_vm.modeAllFranchiseSlides ? 'Tout sélectionner' : 'Selection manuelle',"storedMode":"modeAllFranchiseSlides","storedFrequency":"franchise_frequency_cSlides"},on:{"update:frequency_cSlides":function($event){_vm.my_franchise_frequency_cSlides=$event},"update:frequency_c-slides":function($event){_vm.my_franchise_frequency_cSlides=$event},"modeChange":(val) => {
            _vm.modeAllFranchiseSlides = val;
            _vm.onModeAllSlidesChange('franchise', _vm.modeAllFranchiseSlides);
          },"update:mode-options":(val) => {
            _vm.modeAllFranchiseSlides = val;
            _vm.onModeAllSlidesChange('franchise', _vm.modeAllFranchiseSlides);
          }}}),_vm._l((_vm.company_data.franchise),function(franchise,idx){return _c('v-col',{key:franchise.ref_franchise_by_id.name},[_c('c-tree-view',{attrs:{"title":franchise.ref_franchise_by_id.name,"validation":Boolean(franchise.ref_franchise_by_id.name),"is_selected":_vm.ads_treeview[2].children[idx]},on:{"click":function($event){_vm.company_data.franchise && _vm.company_data.franchise.length > 0 && _vm.toggleChildrenPubliciteTree(2, idx)}}},[_c('Catalogue',{staticClass:"pt-9",attrs:{"company_type":"franchise","company_name":franchise.ref_franchise_by_id.name,"custom_slide_list":franchise.ref_franchise_by_id.custom_slide,"selected_custom_slide_list":_vm.my_franchise_cSlides_list,"removable":false,"selectable":!_vm.modeAllFranchiseSlides,"highlight":_vm.modeAllFranchiseSlides},on:{"selected":_vm.handleCatalogueSelectSlide,"on-img-error":_vm.catalogueFranchise}})],1)],1)})],2)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }