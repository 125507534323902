var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"align-start justify-center fill-height",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mt-10 pt-10",attrs:{"cols":"11","xl":"5"}},[_c('CPublicite',{staticClass:"justify-center",attrs:{"concession_cSlides_list":_vm.concession_cSlides_list,"groupe_cSlides_list":_vm.groupe_cSlides_list,"franchise_cSlides_list":_vm.franchise_cSlides_list,"concession_frequency_cSlides":_vm.concession_frequency_cSlides,"groupe_frequency_cSlides":_vm.groupe_frequency_cSlides,"franchise_frequency_cSlides":_vm.franchise_frequency_cSlides},on:{"update:concession_cSlides_list":(val) => {
          _vm.$emit('update:concession_cSlides_list', val);
        },"update:groupe_cSlides_list":(val) => {
          _vm.$emit('update:groupe_cSlides_list', val);
        },"update:franchise_cSlides_list":(val) => {
          _vm.$emit('update:franchise_cSlides_list', val);
        },"update:concession_frequency_cSlides":(val) => {
          _vm.$emit('update:concession_frequency_cSlides', val);
        },"update:groupe_frequency_cSlides":(val) => {
          _vm.$emit('update:groupe_frequency_cSlides', val);
        },"update:franchise_frequency_cSlides":(val) => {
          _vm.$emit('update:franchise_frequency_cSlides', val);
        }},scopedSlots:_vm._u([{key:"additonal-options",fn:function(){return [_c('v-row',{staticClass:"justify-center pb-10",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","lg":"7"}},[_c('v-btn',{staticClass:"mt-10",attrs:{"color":"deep-purple darken-3","block":""},on:{"click":_vm.toggleFullScreen}},[_vm._v(" "+_vm._s(`${!_vm.isFullscreen ? "Activer le mode plein écran" : "Désactiver le mode plein écran"}`))])],1)],1)]},proxy:true}])})],1),_c('v-col',{staticClass:"mt-12 mb-12 pb-12",attrs:{"cols":"12","xl":"7"}},[_c('v-row',{staticClass:"p-relative",attrs:{"id":"scene_publicite","no-gutters":""}},[_c('v-row',{staticClass:"p-absolute",attrs:{"no-gutters":""}},[_c('v-btn',{staticClass:"btn-fullscreen",attrs:{"color":"deep-purple darken-3","fab":""},on:{"click":_vm.toggleFullScreen}},[_c('v-icon',[_vm._v(_vm._s(`${!_vm.isFullscreen ? "mdi-fullscreen" : "mdi-fullscreen-exit"}`))])],1),_c('v-btn',{staticClass:"btn-floating",attrs:{"color":"deep-purple darken-3","fab":""},on:{"click":_vm.debounced_onPubEnds}},[_c('v-icon',[_vm._v(_vm._s(`mdi-skip-forward`))])],1)],1),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('v-responsive',{attrs:{"aspect-ratio":1.77}},[(_vm.pub_url && _vm.pub_type)?_c('ScenePublicite',{attrs:{"type":_vm.pub_type,"url":_vm.pub_url,"duration_image_publicite":_vm.pub_duration,"hide_cursor":false},on:{"on-publicite-end":_vm.onPubEnds,"on-image-error":_vm.onPubEnds}}):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }