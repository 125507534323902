<template>
  <v-row no-gutters>
    <v-col cols="12" class="pa-0">
      <v-row class="justify-center" no-gutters>
        <vue-element-loading :active="!requested" spinner="bar-fade-scale" :background-color="'rgba(0, 0, 0, 0)'">
          <looping-rhombuses-spinner :animation-duration="1200" :size="95" color="#ff1d5e" />
        </vue-element-loading>
        <v-col cols="12" class="text-center pb-12">
          <v-row justify="center" no-gutters><slot name="additonal-options"></slot></v-row>
        </v-col>
        <v-row justify="center" no-gutters v-if="!logo_concession.url && logo_groupe.length <= 0 && logo_franchise.length <= 0"
          >Aucun logo disponible</v-row
        >
        <v-radio-group v-model="selected_logo" class="pt-10">
          <v-col cols="12" lg="7" class="pa-0 ma-0" v-if="logo_concession.url">
            <v-row class="justify-center" no-gutters>
              <v-col cols="12" class="d-block text-h6 mb-10">Logos associés à votre compte</v-col>
              <v-radio :value="logo_concession" :readonly="!logo_concession.url" :disabled="!logo_concession.url">
                <template v-slot:label>
                  <v-img
                    class="ml-10"
                    min-height="80px"
                    min-width="150px"
                    max-height="80px"
                    max-width="150px"
                    contain
                    @error="onImgError(logo_concession)"
                    :src="logo_concession.url"
                  />
                </template>
              </v-radio>
            </v-row>
          </v-col>
          <v-col cols="12" lg="7" class="pa-0 ma-0">
            <v-row class="justify-center" v-for="(logo, index) in logo_groupe" :key="logo.url" no-gutters>
              <v-radio :value="logo" :readonly="!logo.url" :disabled="!logo.url" v-if="logo.url">
                <template v-slot:label>
                  <v-img
                    class="ml-10"
                    min-height="80px"
                    min-width="150px"
                    max-height="80px"
                    max-width="150px"
                    contain
                    @error="onImgError(logo, index)"
                    :src="logo.url"
                  />
                </template>
              </v-radio>
            </v-row>
          </v-col>

          <v-col cols="12" lg="7" class="pa-0 ma-0">
            <v-row class="justify-center" v-for="(logo, index) in logo_franchise" :key="logo.url" no-gutters>
              <v-radio :value="logo" :readonly="!logo.url" :disabled="!logo.url" v-if="logo.url">
                <template v-slot:label>
                  <v-img
                    class="ml-10"
                    min-height="80px"
                    min-width="150px"
                    max-height="80px"
                    max-width="150px"
                    contain
                    @error="onImgError(logo, index)"
                    :src="logo.url"
                  />
                </template>
              </v-radio>
            </v-row>
          </v-col>

          <!-- TODO -->

          <!-- Un groupe auquel vous etes associé n'a pas de logo, souhaitez lui en ajouter un ? -->
          <!-- Une franchise auquel vous etes associé n'a pas de logo, souhaitez lui en ajouter un ? -->
        </v-radio-group>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import user from "@/utils/mixins/user";
import snackBar from "@/utils/mixins/snackBar";
import logo from "@/utils/mixins/logo";

import { LoopingRhombusesSpinner } from "epic-spinners";

export default {
  name: "CLogo",

  components: {
    LoopingRhombusesSpinner,
  },

  props: ["type", "company_name"],

  mixins: [user, snackBar, logo],

  data() {
    return {
      logo_concession: {},
      logo_groupe: [],
      logo_franchise: [],

      selected_logo: null,

      requested: false,
    };
  },

  mounted() {
    this.getAllLogo();
  },

  methods: {
    async getAllLogo() {
      this.requested = false;
      try {
        this.logo_concession = { ...(await this.$axios.get("/concession/link/logo")).data, type: "concession" };
        this.logo_groupe = (await this.$axios.get("/groupe/link/logo")).data.map((el) => ({ ...el, type: "groupe" }));
        this.logo_franchise = (await this.$axios.get("/franchise/link/logo")).data.map((el) => ({ ...el, type: "franchise" }));
        this.findLogoFromType();
      } catch (error) {
        console.error(error);
        this.displayErrorSnack(`Une erreur s'est produite lors de la récupération des logos`);
      } finally {
        this.requested = true;
      }
    },

    onRadioChange() {
      if (this.selected_logo) {
        this.$emit("update:type", this.selected_logo.type);
        this.$emit("update:company_name", this.selected_logo.company_name);
        this.$emit("logo_link", this.selected_logo.url);
      }
    },

    async onImgError(logo, index) {
      try {
        console.log("error logo", logo);
        if (logo.type == "concession") {
          this.logo_concession = await this.getSpecificConcessionLogo();
        } else if (logo.type == "groupe") {
          this.logo_groupe.splice(index, 1, await this.getSpecificGroupeLogo(logo.company_name));
        } else if (logo.type == "franchise") {
          this.logo_franchise.splice(index, 1, await this.getSpecificFranchiseLogo(logo.company_name));
        }
      } catch (error) {
        console.error(error);
        this.displayErrorSnack(`Une erreur s'est produite lors de la récupération d'un logo`);
      }
    },

    findLogoFromType() {
      if (this.type == "concession" && this.logo_concession.url) {
        this.selected_logo = this.logo_concession;
        // this.onRadioChange();
      } else if (this.type == "groupe" && this.company_name) {
        this.selected_logo = this.logo_groupe.find((el) => el.company_name == this.company_name) || null;
        // this.onRadioChange();
      } else if (this.type == "franchise" && this.company_name) {
        this.selected_logo = this.logo_franchise.find((el) => el.company_name == this.company_name) || null;
        // this.onRadioChange();
      }
    },
  },
  watch: {
    selected_logo: function() {
      this.onRadioChange();
    },
    type: {
      handler: function(val) {
        this.findLogoFromType();
      },
      immediate: true,
    },
    company_name: {
      handler: function(val) {
        this.findLogoFromType();
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
